import {
    faAtom,
    faBalanceScaleLeft,
    faBan, faBars,
    faBolt,
    faBook,
    faBookmark,
    faBug,
    faCalendarDay,
    faCheck, faChevronDown, faChevronUp,
    faClipboardCheck,
    faCog, faDatabase,
    faDolly,
    faDollyFlatbed,
    faEdit,
    faEraser,
    faExchangeAlt,
    faEye,
    faFileAlt,
    faFileInvoice,
    faFireAlt,
    faFlask,
    faIdCard,
    faIndustry,
    faInfoCircle,
    faKey,
    faLanguage,
    faList,
    faMortarPestle,
    faPlus, faPowerOff,
    faPrescriptionBottle,
    faPrint,
    faSearch,
    faShoppingBasket,
    faStoreAlt,
    faTable,
    faTasks,
    faTimes,
    faTools,
    faTrash,
    faTruck,
    faUser,
    faUserLock,
    faUsers,
    faUsersCog,
    faWarehouse,
    faWineBottle
} from "@fortawesome/free-solid-svg-icons";

import {
    faMoneyBillAlt
} from "@fortawesome/free-regular-svg-icons";

// https://fontawesome.com/v5/search?o=r&m=free

export const Icon = {
    logout: faPowerOff,
    up: faChevronUp,
    down: faChevronDown,
    no: faTimes,
    yes: faCheck,
    edit: faEdit,
    cancel: faBan,
    save: faCheck,
    delete: faTrash,
    users: faUsers,
    bars: faBars,
    add: faPlus,
    view: faEye,
    print: faPrint,
    invoice: faFileInvoice,
    calendarDay: faCalendarDay,
    language: faLanguage,
    database: faDatabase,
    clean: faEraser,
    search: faSearch,
    industry: faIndustry,
    userLock: faUserLock,
    usersCog: faUsersCog,
    idCard: faIdCard,
    bug: faBug,
    money: faMoneyBillAlt,
    balanceLeft: faBalanceScaleLeft,
    fileAlt: faFileAlt,
    prescriptionBottle: faPrescriptionBottle,
    exchangeAlt: faExchangeAlt,
    warehouse: faWarehouse,
    basket: faShoppingBasket,
    wineBottle: faWineBottle,
    flask: faFlask,
    pestle: faMortarPestle,
    dolly: faDolly,
    book: faBook,
    bolt: faBolt,
    store: faStoreAlt,
    atom: faAtom,
    dollyFlatbed: faDollyFlatbed,
    list: faList,
    truck: faTruck,
    tasks: faTasks,
    user: faUser,
    key: faKey,
    clipboardCheck: faClipboardCheck,
    info: faInfoCircle,
    tools: faTools,
    cog: faCog,
    fire: faFireAlt,
    bookmark: faBookmark,
    table: faTable
}