import { BaseEntity } from "./base-entity";
import { Permission } from './permission';

export class PermissionGroup extends BaseEntity {
    name: string;
    permissionList: Permission[] = [];

    static fromData(data: any) {
        const e: PermissionGroup = super.fromData(data);
        if (e.permissionList) {
            e.permissionList = e.permissionList.map(x => Permission.fromData(x));
        }
        return e;
    }
}


export enum PermissionGroupFilterField {
    Name = 'Name',
    PermissionType = 'PermissionType'
}