import { Component } from '@angular/core';
import { AppConfig } from 'src/environments/app-config';
import { Language } from '../../enums/language';
import { PageType } from '../../enums/page-type';
import { FormInput } from '../../models/forms/base/form-input';
import { FormInputOptions } from '../../models/forms/base/form-input-options';
import { PageRoute, PageRouteGroup } from '../../models/page-route';
import { User } from '../../models/user';
import { PageRouteService } from '../../services/page-route.service';
import { BasePage } from '../base-page';
import { BasePageDeps } from '../base-page-deps';

@Component({
    selector: 'app-private-base-page',
    templateUrl: './private-base.page.html',
    styleUrls: ['./private-base.page.less']
})
export class PrivateBasePage extends BasePage {
    showMenu = false;
    showSelectlanguage = false;
    PageType = PageType;
    user: User;
    language: FormInput<Language>;
    appVersion = AppConfig.version;

    static menuState = {
        key: 'menu_state_key',
        opened: 'opened',
        closed: 'closed'
    };

    constructor(
        public pageRouteService: PageRouteService,
        // public systemService: SystemService,
        deps: BasePageDeps
    ) {
        super(deps);
        this.pageRouteService.setPages();
        this.user = this.userService.getUser();
        this.language = new FormInput<Language>(this.i18n.t.core.label.selectLanguage, Language.PTBR)
            .Options([
                new FormInputOptions(Language.PTBR, 'Português'),
                new FormInputOptions(Language.EN, 'English'),
            ]);

        let menuState = localStorage.getItem(PrivateBasePage.menuState.key);
        if(menuState == PrivateBasePage.menuState.closed) this.showMenu = false;
        if(menuState == PrivateBasePage.menuState.opened) this.showMenu = true;
    }

    openPage(page: PageRoute) {
        if (document.body.offsetWidth <= 650) {
            this.showMenu = false;
        }
        this.pageRouteService.open(page)
    }

    toggleMenu() {
        this.showMenu = !this.showMenu;
        if(this.showMenu)
            localStorage.setItem(PrivateBasePage.menuState.key, PrivateBasePage.menuState.opened)
        else
            localStorage.setItem(PrivateBasePage.menuState.key, PrivateBasePage.menuState.closed)
    }

    logout() {
        this.alert.confirm(this.i18n.t.core.label.confirmLogout)
            .then(() => {
                this.userService.logout();
                this.pageRouteService.goToLogin();
            })
            .catch(() => { });
    }

    saveLanguage() {
        this.block.start();
        this.i18n.changeLanguage(this.language.value);
        window.location.reload();
    }

    openGroup(group: PageRouteGroup) {
        group.opened = !group.opened;
        if(group.opened && this.pageRouteService.pageList.length > 12){
            this.pageRouteService.pageGroupList.forEach(x => {
                if(x.title != group.title){
                    x.opened = false;
                }
            })
        }
    }
}
