export class PageRoute {
    constructor(
        public title: string,
        public route: string,
        public icon: any
    ) { }
}

export class PageRouteGroup {
    constructor(
        public title: string,
        public icon: any
    ) { }
    public pageList: PageRoute[] = [];
    public opened = false;
}