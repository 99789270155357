import { User } from "../user";
import { BaseForm } from "./base/base-form";
import { FormInput } from "./base/form-input";
import { FormInputType } from "./base/form-input-type";

export class UserProfileForm extends BaseForm<User> {
    name: FormInput<string>;
    username: FormInput<string>;
    email: FormInput<string>;
    password: FormInput<string>;

    configure() {
        this.name = this.Input<string>(this.i18n.t.core.user.name).Required();
        this.username = this.Input<string>(this.i18n.t.core.user.username).Required().Disabled();;
        this.email = this.Input<string>(this.i18n.t.core.user.email).Required().Disabled();

        this.password = this.Input<string>(this.i18n.t.core.userProfile.newPassword).Required().Type(FormInputType.PASSWORD);

        if (this.model) {
            this.name.SetValue(this.model.name);
            this.username.SetValue(this.model.username);
            this.email.SetValue(this.model.email);
        }
    }
}