import { BasePageDeps } from '../../../pages/base-page-deps';
import { BaseEntity } from '../../base-entity';
import { FilterDTO } from '../../filter/filter-dto';
import { FilterFieldDTO } from '../../filter/filter-field-dto';
import { FilterFieldSearchType } from '../../filter/filter-field-search-type';
import { FilterOrder } from "../../filter/filter-order";
import { FormInputOptions } from './form-input-options';
import { I18n } from '../../../i18n';

export class AutocompleteFormInput<T extends BaseEntity> {
    results: FormInputOptions[] = [];
    resultModelList: T[] = [];
    useCurrentLanguage = false;

    selected: T;
    constructor(
        public filterRoute: string,
        public field: string,
        private modelType: any,        
        public getLabel: (model: T) => string,
        private onChangeInput: Function,
        private setLabel: (label: FormInputOptions) => void
    ) { }

    search(event) {
        this.privateSearch(event.query);
    }

    getSelectedModel(): T {
        return this.selected;
    }

    onSelect(item: FormInputOptions) {        
        this.selected = this.resultModelList.First(x => x.id == item.value);
        this.onChangeInput();
    }

    SetValue(value: T) {
        this.selected = value;
        let option = new FormInputOptions(value.id, this.getLabel(value));
        this.setLabel(option);
    }

    UseCurrentLanguage(useCurrentLanguage = true) {
        this.useCurrentLanguage = useCurrentLanguage;
    }

    onUnselect() {
        this.selected = null;
        this.onChangeInput();
    }

    private privateSearch(queryParam: string): Promise<void> {
        return new Promise((resolve) => {
            if (!BasePageDeps.current.service || !this.filterRoute || !this.modelType) return;

            BasePageDeps.current.block.start();
            BasePageDeps.current.service.Post(this.filterRoute, this.getSearchDTO(queryParam))
                .then(filter => {
                    this.resultModelList = filter.data.map(x => this.modelType.fromData(x));

                    this.results = this.resultModelList.map(x =>
                        new FormInputOptions(x.id, this.getLabel(x)));

                    resolve();
                })
                .catch(err => BasePageDeps.current.logger.error(err))
                .then(() => {
                    BasePageDeps.current.block.stop()
                });
        });
    }

    private getSearchDTO(queryParam: string): FilterDTO {
        let dto = new FilterDTO();
        dto.pageSize = 10;
        dto.page = 1;

        let field = new FilterFieldDTO();
        field.argument = queryParam;
        field.type = FilterFieldSearchType.LIKE;
        field.field = this.field;

        dto.fields = [field];
        if(this.useCurrentLanguage) {
            let lang = new FilterFieldDTO();
            lang.argument = I18n.current.t.code;
            lang.type = FilterFieldSearchType.EQUAL;
            lang.field = 'Language';

            dto.fields.push(lang);
        }        

        dto.orderByDirection = FilterOrder.Asc;
        dto.orderByField = this.field;
        return dto;
    }
}
