import { MeasurementUnitDimension } from "../../enums/measurement-unit-dimention";
import { MeasurementUnit } from "../measurement-unit";
import { BaseForm } from "./base/base-form";
import { FormInput } from "./base/form-input";
import { FormInputOptions } from "./base/form-input-options";
import { FormInputType } from "./base/form-input-type";

export class MeasurementUnitForm extends BaseForm<MeasurementUnit> {
    name: FormInput<string>;
    namePlural: FormInput<string>;
    symbol: FormInput<string>;

    dimension: FormInput<MeasurementUnitDimension>;
    defaultConversionFactor: FormInput<number>;
    isDefault: FormInput<boolean>;

    configure() {
        this.name = this.Input<string>(this.i18n.t.core.measurementUnit.name).Required();
        this.namePlural = this.Input<string>(this.i18n.t.core.measurementUnit.namePlural);
        this.symbol = this.Input<string>(this.i18n.t.core.measurementUnit.symbol).Required();

        this.dimension = this.Input<MeasurementUnitDimension>(this.i18n.t.core.measurementUnit.dimension)
            .Type(FormInputType.SELECT)
            .Options(FormInputOptions.fromEnum(MeasurementUnitDimension, this.i18n.t.core.enum.MeasurementUnitDimension))
            .Required();

        this.defaultConversionFactor = this.Input<number>(this.i18n.t.core.measurementUnit.defaultConversionFactor)
            .Type(FormInputType.NUMBER)
            .Required();

        this.isDefault = this.Input<boolean>(this.i18n.t.core.measurementUnit.isDefault)
            .Type(FormInputType.CHECKBOX)
            .Required();


        if (this.model) {
            this.name.SetValue(this.model.name);
            this.namePlural.SetValue(this.model.namePlural);
            this.symbol.SetValue(this.model.symbol);
            this.dimension.SetValue(this.model.dimension);
            this.defaultConversionFactor.SetValue(this.model.defaultConversionFactor);
            this.isDefault.SetValue(this.model.isDefault);
        }
    }

    getDTO(): MeasurementUnit {
        let dto = new MeasurementUnit();
        dto.name = this.name.value;
        dto.namePlural = this.namePlural.value;
        dto.symbol = this.symbol.value;
        dto.dimension = this.dimension.value;
        dto.defaultConversionFactor = this.defaultConversionFactor.value;
        dto.isDefault = this.isDefault.value;
        if (this.model) dto.id = this.model.id;
        return dto;
    }

}