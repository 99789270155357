<nav>
    <div class="header"></div>
    <div class="body">
        <div class="loginbox">
            <div class="login-text1">
                <img  src="/assets/img/logo-full.png">
            </div>
            <div class="swith-login-mode" *ngIf="showQuickLogin">
                <a (click)="quickLogin()">LOGIN Admin</a>
                <a (click)="quickLoginTest()" style="float: right;">LOGIN Usuário</a>               
            </div>
            <div *ngIf="useMs">
                <button (click)="msLogin()">{{ i18n.t.core.label.msLogin }}</button>
                <div class="swith-login-mode" >
                    <a (click)="togleAuthMode()">{{ i18n.t.core.label.useCredentialsLoginMode }}</a>
                </div>
            </div>
            
            <div *ngIf="!useMs">
                <input [(ngModel)]="data.username" placeholder="{{ i18n.t.core.user.username }}" (keydown.enter)="login()">          
                <input [(ngModel)]="data.password" type="password" placeholder="{{ i18n.t.core.user.password }}" (keydown.enter)="login()">
                <button (click)="login()">{{ i18n.t.core.label.login }}</button>     
                <div class="swith-login-mode" >
                    <a (click)="togleAuthMode()">{{ i18n.t.core.label.useMsLoginMode }}</a>
                </div>
            </div>             
            
            <div class="forgot-password-text">v.{{ appVersion }}</div>        
        </div>
    </div>
</nav>
