import { Injectable } from "@angular/core";
import { ApiRoute } from '../../core/enums/api-route';
import { ServiceHandler } from '../../core/handlers/service.handler';
import { Currency } from "../models/currency";

@Injectable()
export class CurrencyService {

    constructor(
        private service: ServiceHandler,
    ) { }

    list(): Promise<Currency[]> {
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.currency.list)
                .then(data => {
                    resolve(data.map(x => Currency.fromData(x)));
                })
                .catch(err => reject(err));
        });
    }
}