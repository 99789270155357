<div class="table">
    <div *ngIf="table?.description" class="table-description">
        {{ table.description }}
    </div>
    <p-table [value]="table.data" (sortFunction)="sort($event)" [customSort]="true" id="{{ table.guid }}"
        [autoLayout]="true">
        <ng-template pTemplate="header">

            <tr *ngIf="table.data.length">

                <th *ngIf="table.showSelectionInput">
                    <g-form-input [input]="masterSelection"></g-form-input>
                </th>

                <th *ngFor="let c of table.columnList" [pSortableColumn]="c.orderBy" width="{{ c.width }}"
                    class="{{ getPriorityClass(c) }}">
                    <p-sortIcon *ngIf="c.orderBy" [field]="c.orderBy"></p-sortIcon>
                    {{ c.label }}
                </th>

                <th class="action" *ngIf="table.actionList.length" width="{{ getActionsWidth() }}">
                    {{ i18n.t.core.label.actions }}
                </th>
            </tr>


            <tr *ngIf="!table.data.length">
                <span class="no-result-message">
                    {{ i18n.t.core.label.noResults }}
                </span>
            </tr>

        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr id="{{ item.guid }}" [class.row-selected]="item.tableComponent_selectionInput.value">

                <td *ngIf="table.showSelectionInput">
                    <g-form-input [input]="item.tableComponent_selectionInput"></g-form-input>
                </td>

                <td *ngFor="let c of table.columnList" class="{{ getPriorityClass(c) }} {{ getClassName(c, item) }}" [class.clickable]="hasClickFunction(c, item)" (click)="executeClick(c, item)">
                    {{ c.getValue(item) }}
                </td>

                <td class="action" *ngIf="table.actionList.length">
                    <section>
                        <fa-icon *ngFor="let act of table.actionList" 
                        [icon]="act.icon" (click)="act.click(item)" 
                        [class.disabled]="act.isDisabled(item)"
                        pTooltip="{{ act.tooltip }}"
                        tooltipPosition="top">
                        </fa-icon>
                    </section>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <section class="base-section">
        <div class="counter" *ngIf="filter.hasData()">
            {{ getCounterString() }}
        </div>

        <p-paginator [style.display]="filter.hasData() ? '' : 'none'" [rows]="filter.pageSize"
            [totalRecords]="filter.totalResults" (onPageChange)="filter.paginate($event)" #paginator>
        </p-paginator>

        <div class="page-size" *ngIf="filter.hasData()">
            {{ i18n.t.core.label.show }}
            <select [(ngModel)]="filter.pageSize" (change)="onChangePageSize()">
                <option *ngFor="let s of filter.pageSizeOptions" value="{{ s }}">{{ s }}</option>
            </select>
            {{ i18n.t.core.label.perPage }}
        </div>
    </section>

</div>