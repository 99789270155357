import { Component } from '@angular/core';
import { ApiRoute } from '../../enums/api-route';
import { PermissionType } from '../../enums/permission-type';
import { FormInputOptions } from '../../models/forms/base/form-input-options';
import { UserForm } from '../../models/forms/user.form';
import { PermissionGroup } from '../../models/permission-group';
import { User, UserFilterField } from '../../models/user';
import { PermissionGroupService } from '../../services/permission-group.service';
import { BaseFilterPage } from '../base-filter-page';
import { BasePageDeps } from '../base-page-deps';

@Component({
    selector: 'app-core-user-page',
    templateUrl: './user.page.html',
    styleUrls: ['./user.page.less']
})
export class UserPage extends BaseFilterPage<User, UserForm> {
    permissionGroups: PermissionGroup[] = [];
    constructor(
        deps: BasePageDeps,
        private permissionGroupService: PermissionGroupService
    ) {
        super(deps, User, UserForm, ApiRoute.user.filter, ApiRoute.user.default);
        this.title = this.i18n.t.core.user.title;
        this.mainPermissionType = PermissionType.Core_User;
    }

    loadScreenDeps(): Promise<void> {
        this.filter.AfterSearch(() => {
            if (this.permissionGroups) {
                this.filter.data.forEach(x => {
                    x.permissionGroupUsers.forEach(p => {
                        p.permissionGroup = this.permissionGroups.First(y => y.id == p.permissionGroupId)
                    })
                })
            }
        });

        return new Promise(resolve => {
            Promise.all([
                this.permissionGroupService.list()
            ])
                .then(values => {
                    this.permissionGroups = values[0].OrderBy(x => x.name) as PermissionGroup[];
                })
                .catch(() => { })
                .then(() => { resolve() })
        });
    }

    formDeps(form: UserForm) {
        form.permissionGroups = this.permissionGroups;
        return form;
    }

    createFilter() {
        this.filter.CreateField(this.i18n.t.core.user.name, UserFilterField.Name);
        this.filter.CreateField(this.i18n.t.core.user.email, UserFilterField.Email);
        this.filter.CreateField(this.i18n.t.core.user.username, UserFilterField.Username);
        this.filter.CreateField(this.i18n.t.core.user.permissionGroup, UserFilterField.PermissionGroupId)
            .Options(this.permissionGroups.map(x => new FormInputOptions(x.id, x.name)))
    }
    createTable() {
        this.defaultCrudTableActions();

        this.table.Column()
            .Label(this.i18n.t.core.user.name)
            .OrderBy(UserFilterField.Name)
            .Value(x => x.name)
            .Priority(3);

        this.table.Column()
            .Label(this.i18n.t.core.user.email)
            .OrderBy(UserFilterField.Email)
            .Value(x => x.email)
            .Priority(4);

        this.table.Column()
            .Label(this.i18n.t.core.user.username)
            .OrderBy(UserFilterField.Username)
            .Value(x => x.username);

        this.table.Column()
            .Label(this.i18n.t.core.user.permissionGroup)
            .Value(x => x.permissionGroupUsers.map(y => y.permissionGroup?.name).join(', '))
            .Priority(3);
    }
}
