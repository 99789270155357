import { PermissionLevel } from '../../enums/permission-level';
import { PermissionType } from '../../enums/permission-type';
import { Permission } from '../permission';
import { BaseForm } from './base/base-form';
import { FormInput } from './base/form-input';
import { FormInputOptions } from './base/form-input-options';
import { FormInputType } from './base/form-input-type';

export class PermissionForm extends BaseForm<Permission> {
    level: FormInput<PermissionLevel>;
    type: FormInput<PermissionType>;

    configure() {
        this.level = this.Input<PermissionLevel>(this.i18n.t.core.permission.level)
            .Options(FormInputOptions.fromEnum(PermissionLevel, this.i18n.t.core.enum.PermissionLevel))
            .Required();

        this.type = this.Input<PermissionType>(this.i18n.t.core.permission.type)
            .Options(FormInputOptions.fromEnum(PermissionType, this.i18n.t.core.enum.PermissionType))
            .Type(FormInputType.FILTER_SELECT)
            .Required();

        this.type.options = this.type.options.OrderBy(x => x.label);

        if (this.model) {
            this.level.SetValue(this.model.level);
            this.type.SetValue(this.model.type);
        }
    }

    getDTO(): Permission {
        let dto = new Permission();
        dto.level = this.level.value;
        dto.type = this.type.value;
        // if (this.model) dto.id = this.model.id;
        return dto;
    }
}