import { EnvConfig } from './env-config-interface';
import { LogLevel } from './log-level';

export const environment: EnvConfig = {  
  production: true,
  apiUrl: 'https://s3-api.livbox.com.br',
  logLevel: LogLevel.ALL,
  msal: {
    clientId: '739bd455-7e57-459c-bff5-e286f1e77ad3',
    tenantId: '24045210-dd26-47e0-a57a-e501d3cfc8c0'
  },
  showQuickLogin: false,
};
