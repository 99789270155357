import { Injectable } from "@angular/core";
import { ApiRoute } from '../../core/enums/api-route';
import { ServiceHandler } from '../../core/handlers/service.handler';
import { StockProduct } from "../models/stock-product";
import { StockProductBatchNumbers } from "../models/stock-product-batch-numbers";

@Injectable()
export class StockProductService {

    constructor(
        private service: ServiceHandler,
    ) { }

    getByProductTypeId(productTypeId: number): Promise<StockProduct[]> {
        return new Promise((resolve, reject) => {
            let route = ApiRoute.stockProduct.productType.replace('{productTypeId}', productTypeId.toString());
            this.service.Get(route)
                .then(data => {
                    resolve(data.map(x => StockProduct.fromData(x)));
                })
                .catch(err => reject(err));
        });
    }

    getAvailableProductBatchNumbers(productIds: number[]): Promise<StockProductBatchNumbers[]> {
        return new Promise((resolve, reject) => {
            let route = ApiRoute.stockProduct.batchNumbers;
            this.service.Post(route, productIds)
                .then(data => {
                    resolve(data.map(x => StockProductBatchNumbers.fromData(x)));
                })
                .catch(err => reject(err));
        });
    }
}