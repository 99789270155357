import { Component, Input } from "@angular/core";
import { Icon } from '../../enums/icon';
import { Filter } from '../../models/filter/filter';
import { FilterField } from '../../models/filter/filter-field';
import { FormInputType } from '../../models/forms/base/form-input-type';
import { I18n } from '../../i18n';



@Component({
    selector: 'g-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.less']
})
export class FilterComponent<T>{
    icon = Icon;
    FormInputType = FormInputType;
    @Input() filter: Filter<T>;
    constructor(public i18n: I18n) { }

    getLayoutSizeClass(field: FilterField) {
        let largeLayout = 'ui-lg-3 ui-md-6';
        if (field.layoutSize == 2) {
            largeLayout = 'ui-lg-6 ui-md-12';
        }
        return largeLayout;
    }

    search() {
        this.filter.resetPage();
    }

    clean() {
        this.filter.clean();
    }

}
