import { BlockHandler } from '../../handlers/block.handler';
import { LogHandler } from "../../handlers/log.handler";
import { ServiceHandler } from "../../handlers/service.handler";
import { StorageHandler } from "../../handlers/storage.handler";
import { I18n } from '../../i18n';
import { BasePageDeps } from '../../pages/base-page-deps';
import { UserService } from "../../services/user.service";
import { FormInputOptions } from '../forms/base/form-input-options';
import { FormInputType } from "../forms/base/form-input-type";
import { FilterDTO } from "./filter-dto";
import { FilterField } from "./filter-field";
import { FilterFieldDTO } from "./filter-field-dto";
import { FilterFieldSearchType } from "./filter-field-search-type";
import { FilterOrder } from "./filter-order";


const PAGE_SIZE_KEY = 'filter_page_size';
class PageSizeItem {
    constructor(
        public size: number
    ) { }
}

export class Filter<T> {
    fields: FilterField[] = [];
    lastFieldsUsed: FilterField[] = [];
    data: T[] = [];
    pageSizeOptions = [10, 15, 20, 50, 100];
    pageSize: number;
    totalResults: number = 0;
    page: number = 1;

    orderBy: string;
    orderDirection: FilterOrder;

    service: ServiceHandler;
    logger: LogHandler;
    filterRoute: string;
    baseModelType: any;
    userService: UserService;

    afterSearchEvent: Function[] = [];

    onResetPage: Function[] = [];
    skipSearch: number = 0;
    showFilterArea = true;


    block: BlockHandler;
    onCleanPage: Function[] = [];

    static filterState = {
        key: 'filter_state_key',
        opened: 'true',
        closed: 'false'
    };

    constructor() {
        let pageSize = StorageHandler.List(PAGE_SIZE_KEY) as PageSizeItem[];
        if (!!pageSize && pageSize.Any()) {
            this.pageSize = Number(pageSize.First().size);
        } else {
            this.pageSize = this.pageSizeOptions[0];
        }

        let filterState = localStorage.getItem(Filter.filterState.key);
        if (filterState == Filter.filterState.closed) this.showFilterArea = false;
    }

    configure(filterRoute: string, baseModelType: any, deps: BasePageDeps) {
        this.service = deps.service;
        this.logger = deps.logger;
        this.filterRoute = filterRoute;
        this.baseModelType = baseModelType;
        this.userService = deps.userService;
        this.block = deps.block;
        return this;
    }

    /* Métodos para serem utilizados nas Pages */
    CreateField(label: string, field: string, type = FormInputType.TEXT) {
        let filterField = new FilterField(label, field).Type(type);
        this.fields.push(filterField);
        return filterField;
    }

    CreateYesNoField(label: string = I18n.current.t.core.label.active, field: string) {
        let filterField = new FilterField(label, field)
            .Options([
                new FormInputOptions(true, I18n.current.t.core.label.yes),
                new FormInputOptions(false, I18n.current.t.core.label.no),
            ], true);
        this.fields.push(filterField);
        return filterField;
    }

    CreateDateRangeFilters(label: string, field: string) {
        this.CreateField(label + ' - ' + I18n.current.t.core.label.filterFrom, field, FormInputType.DATE)
            .FilterType(FilterFieldSearchType.GREATER_EQUAL)

        let toFilter = this.CreateField(label + ' - ' + I18n.current.t.core.label.filterTo, field, FormInputType.DATE)
            .FilterType(FilterFieldSearchType.LESS_EQUAL)
            .OnChange(() => {
                if (toFilter.value) {
                    toFilter.value = Date.GetLastInstant(toFilter.value)
                }
            })
    }


    AfterSearch(afterSearch: Function) {
        this.afterSearchEvent.push(afterSearch);
        return this;
    }

    SkipSearch(n: number = 1): Filter<T> {
        this.skipSearch = n;
        return this;
    }

    PageSizeOptions(pageSizeOptions: number[]) {
        this.pageSizeOptions = pageSizeOptions;
        this.pageSize = this.pageSizeOptions[0];
        return this;
    }
    /* Métodos para serem utilizados nas Pages */


    paginate(event) {
        if (this.setPaginate(event)) {
            this.privateSearch().then(() => this.onPaginateOrSort());
        }
    }

    sort(event) {
        if (this.setOrder(event)) {
            this.privateSearch().then(() => this.onPaginateOrSort());
        }
    }

    private onPaginateOrSort() {

    }

    private setPaginate(paginateEvent): boolean {
        if (paginateEvent.page + 1 != this.page) {
            this.page = paginateEvent.page + 1;
            this.pageSize = paginateEvent.rows;
            return true;
        }
        return false;
    }

    private setOrder(orderEvent): boolean {
        if (!this.sameOrder(orderEvent)) {
            this.orderBy = orderEvent.field;
            this.orderDirection = orderEvent.order == 1 ? FilterOrder.Asc : FilterOrder.Desc;
            return true;
        }
        return false;
    }

    sameOrder(orderEvent): boolean {
        let n = this.orderDirection == FilterOrder.Asc ? 1 : -1;
        return orderEvent.order == n && this.orderBy == orderEvent.field;
    }

    getDTO(): FilterDTO {
        let dto = new FilterDTO();
        dto.pageSize = this.pageSize;
        dto.page = this.page;
        dto.totalResults = this.totalResults;
        dto.fields = [];
        let fields = this.fields.filter(x => x.type != FormInputType.MULTI_SELECT);

        let multiSelectFields = this.fields.filter(x => x.type == FormInputType.MULTI_SELECT);

        multiSelectFields.forEach(x => {
            if (x.value && Array.isArray(x.value)) {
                x.value.forEach(subValue => {
                    let subField = Object.assign({}, x);
                    subField.value = subValue;
                    fields.push(subField);
                })
            }
        });

        fields.forEach(field => {
            if (!field.ignoreValueOnFilter.Contains(field.value)) {
                let fieldDto = new FilterFieldDTO();
                fieldDto.type = FilterFieldSearchType.EQUAL;
                fieldDto.field = field.field;
                fieldDto.argument = field.value;
                if (field.type == FormInputType.TEXT) {
                    fieldDto.type = FilterFieldSearchType.LIKE;
                }
                if (field.filterType) {
                    fieldDto.type = field.filterType;
                }
                dto.fields.push(fieldDto);
            }
        });
        dto.orderByDirection = this.orderDirection;
        dto.orderByField = this.orderBy;
        return dto;
    }

    clean() {
        this.fields.forEach(field => {
            field.reset();
        });
        this.onCleanPage.forEach(f => f());
        this.resetPage();
    }

    search(): Promise<void> {
        return this.privateSearch();
    }

    private privateSearch(): Promise<void> {
        return new Promise((resolve) => {
            if (!this.service || !this.filterRoute || !this.baseModelType) return;
            if (this.skipSearch > 0) {
                this.skipSearch--;
                this.block.stop();
                return resolve();
            }
            this.block.start();
            this.service.Post(this.filterRoute, this.getDTO())
                .then(filter => {
                    this.cloneLastFields();
                    this.data = filter.data.map(x => this.baseModelType.fromData(x));
                    this.totalResults = filter.totalResults;

                    this.afterSearchEvent.forEach(f => f());

                    resolve();
                })
                .catch(err => this.logger.error(err))
                .then(() => {
                    this.block.stop()
                });
        });
    }

    private cloneLastFields() {
        this.lastFieldsUsed = this.fields
            .map(x => Object.assign(new FilterField(x.label, x.field), x));
    }

    hasData() {
        return this.data && this.data.length > 0;
    }

    resetPage(cleanSelection = true): Promise<void> {
        this.page = 1;

        this.onResetPage.forEach((f) => f());

        if (cleanSelection) {
            return this.search();
        } else {
            return this.privateSearch();
        }
    }

    OnResetPage(f: Function) {
        this.onResetPage.push(f);
        return this;
    }

    OnCleanPage(f: Function) {
        this.onCleanPage.push(f);
        return this;
    }

    toggleFilterArea() {
        this.showFilterArea = !this.showFilterArea;
        if (this.showFilterArea)
            localStorage.setItem(Filter.filterState.key, Filter.filterState.opened)
        else
            localStorage.setItem(Filter.filterState.key, Filter.filterState.closed)
    }

    onChangePageSize() {
        this.pageSize = Number(this.pageSize);
        StorageHandler.DeleteTable(PAGE_SIZE_KEY);
        StorageHandler.Save(PAGE_SIZE_KEY, new PageSizeItem(this.pageSize));
        this.resetPage(false);
    }
}

