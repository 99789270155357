import { Component, Input } from "@angular/core";

@Component({
    selector: 'g-label-info',
    templateUrl: './label-info.component.html',
    styleUrls: ['./label-info.component.less']
})
export class LabelInfoComponent {
    @Input() label: string;
    @Input() info: string;
}