<div class="filters" [class.closed]="!filter.showFilterArea">

    <div class="ui-g form">
        <div *ngFor="let field of filter.fields" class="{{ getLayoutSizeClass(field) }} ui-sm-12 filter-field">
            <g-form-input [input]="field" (enterKey)="search()"></g-form-input>
        </div>
    </div>

    <div class="buttons form">
        <div class="button-base">
            <button class="btn" (click)="clean()">
                <fa-icon [icon]="icon.clean"></fa-icon>
                {{ i18n.t.core.label.cleanFilter }}
            </button>
        </div>
        <div class="button-base">
            <button class="btn" (click)="search()">
                <fa-icon [icon]="icon.search"></fa-icon>
                {{ i18n.t.core.label.searchFilter }}
            </button>
        </div>
    </div>

</div>