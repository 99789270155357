import { BaseModel } from "./base-model";

export class BaseEntity extends BaseModel {
    id: number;
    logicalDeleteDate: Date;
    createdDate: Date;
    updatedDate: Date;

    static fromData(data: any) {
        const e = super.fromData(data);
        if (e.logicalDeleteDate) e.logicalDeleteDate = new Date(e.logicalDeleteDate);
        if (e.createdDate) e.createdDate = new Date(e.createdDate);
        if (e.updatedDate) e.updatedDate = new Date(e.updatedDate);
        return e;
    }

    Model(model: BaseEntity) {
        if(model) this.id = model.id;
        return this;
    }
}