interface Number {
    ToCurrency(): string;
    ToThousandSeparator(decimalStots?: number | null): string
}

Number.prototype.ToCurrency = function (): string {  
    let decimal = ',';
    let thousand = '.'  
    var i = parseFloat(this.toString());
    if(isNaN(i)) { i = 0.00; }
    var minus = '';
    if(i < 0) { minus = '-'; }
    i = Math.abs(i);
    i = parseInt(((i + .005) * 100).toString());
    i = i / 100;
    let s = i.toString()
    if(s.indexOf('.') < 0) { s += '.00'; }
    if(s.indexOf('.') == (s.length - 2)) { s += '0'; }
    s = minus + s;    
    s = s.replace('.', decimal).replace(/\B(?=(\d{3})+(?!\d))/g, thousand);
    return s;
}

Number.prototype.ToThousandSeparator = function (decimalStots: number | null = null): string {  
    let decimal = ',';  
    let thousand = '.'  
    let number = this;
    if(decimalStots == null) decimalStots = 3;
    let split = number.toString().split('.');
    let integerPart = split[0];
    let decimalPart = split.length > 1 ? split[1] : '';
    if(decimalPart.length > 3){
        number = Number(number.toFixed(decimalStots));
        split = number.toString().split('.');
        integerPart = split[0];
        decimalPart = split[1];
    }
    let finalString = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousand);
    if(!!decimalPart){        
        finalString += decimal + decimalPart;
    }
    return finalString;
}

