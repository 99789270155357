import { Component, OnInit } from "@angular/core";
import { UserProfileForm } from "../../models/forms/user-profile.form";
import { BasePage } from "../base-page";
import { BasePageDeps } from "../base-page-deps";

@Component({
    selector: 'app-core-user-profile.page',
    templateUrl: './user-profile.page.html',
    styleUrls: ['./user-profile.page.less']
})
export class UserProfilePage extends BasePage implements OnInit {
    form: UserProfileForm = new UserProfileForm();
    showChangePassword = false;
    constructor(
        deps: BasePageDeps
    ) {
        super(deps);
        this.title = this.i18n.t.core.userProfile.title;
        this.headerCustomButtons.push({
            action: () => this.save(),
            label: this.i18n.t.core.label.save,
            icon: this.icon.save
        });
    }

    ngOnInit() {
        var user = this.userService.getUser();
        this.form.Model(user);
    }

    save() {
        this.form.name.showValidation = true;
        if (!this.form.name.getErrorMessage()) {
            this.block.start();
            this.userService.saveProfile(this.form.name.value)
                .then(() => window.location.reload())
        }
    }

    savePassword() {
        this.form.password.showValidation = true;
        if (!this.form.password.getErrorMessage()) {
            this.block.start();
            this.userService.savePassword(this.form.password.value)
                .then(() => {
                    this.alert.success(this.i18n.t.core.userProfile.passwordSaveSuccess);
                    this.showChangePassword = false;
                    this.form.password.SetValue(null);
                })
                .catch(() => this.alert.success(this.i18n.t.core.userProfile.passwordSaveError))
                .finally(() => this.block.stop())
        }
    }

}