
import { I18nTags } from '../../enums/i18n-tags';
import { Language } from '../../enums/language';
import { LocaleType } from "../locale-type";
import { CalendarLocale } from "./calendar";
import { EnumLocale } from "./enum";
import { PermissionType } from '../../enums/permission-type';

export let Locale: LocaleType = {
    code: Language.PTBR,
    calendar: CalendarLocale,

    navigation: {
        core: {
            user: EnumLocale.PermissionType[PermissionType.Core_User],
            currency: EnumLocale.PermissionType[PermissionType.Core_Currency],
            permissionGroup: EnumLocale.PermissionType[PermissionType.Core_PermissionGroup],
            logText: EnumLocale.PermissionType[PermissionType.Core_LogText],
            measurementUnit: EnumLocale.PermissionType[PermissionType.Core_MeasurementUnit],
            auditModel: EnumLocale.PermissionType[PermissionType.Core_Audit],
        },  
        crm: {
            company: EnumLocale.PermissionType[PermissionType.Crm_Company],
        },
        products: {
            productTypeConferenceType: EnumLocale.PermissionType[PermissionType.Products_ProductTypeConferenceType],
            productType: EnumLocale.PermissionType[PermissionType.Products_ProductType],
            product: EnumLocale.PermissionType[PermissionType.Products_Product],
            supplierCompany: EnumLocale.PermissionType[PermissionType.Products_SupplierCompany],
            supplierProduct: EnumLocale.PermissionType[PermissionType.Products_SupplierProduct],
        },
        stock: {
            stockMovement: EnumLocale.PermissionType[PermissionType.Stock_StockMovement],
            stockproductType: EnumLocale.PermissionType[PermissionType.Stock_StockProductType],
            transporterCompany: EnumLocale.PermissionType[PermissionType.Stock_TransporterCompany],
        },
        quality:{
            qualityReview: EnumLocale.PermissionType[PermissionType.Quality_QualityReview],
            qualityReviewInternalProducts: EnumLocale.PermissionType[PermissionType.Quality_QualityReview_InternalProducts],
            qualityReviewOtherCategories: EnumLocale.PermissionType[PermissionType.Quality_QualityReview_OtherCategories],
        },
        production: {
            reactor: EnumLocale.PermissionType[PermissionType.Production_Reactor],
            productionRecipe: EnumLocale.PermissionType[PermissionType.Production_ProductionRecipe],
            productionBatch: EnumLocale.PermissionType[PermissionType.Production_ProductionBatch],
            productionBatchExecution: EnumLocale.PermissionType[PermissionType.Production_ProductionBatch_Execution],
            productApplication: EnumLocale.PermissionType[PermissionType.Production_ProductApplication],
        }
    },

    core: {
        label: {
            cleanFilter: 'Limpar',
            searchFilter: 'Consultar',
            actions: 'Ações',
            noResults: 'Sem Resultados',
            filterResult: `Exibindo resultados de ${I18nTags.StarCount} a ${I18nTags.EndCount}, de um total de ${I18nTags.Total}.`,            
            yes: 'Sim',
            no: 'Não',
            select: 'Selecione',
            show: 'Mostrar',
            perPage: 'por Página',
            requiredField: 'Campo obrigatório não preenchido',
            cancel: 'Cancelar',
            save: 'Salvar',
            delete: 'Excluir',
            logout: 'Sair',
            login: 'Entrar',
            useMsLoginMode: 'Entrar com Microsoft',
            useCredentialsLoginMode: 'Entrar com Credenciais',
            msLogin: 'Entrar com Microsoft',
            add: 'Incluir',
            showFilter: 'Expandir Filtros',
            hideFilter: 'Recolher Filtros',            
            confirmLogout: 'Tem certeza que deseja sair do sistema?',
            close: 'Fechar',
            edit: 'Editar',
            back: 'Voltar',
            language: 'Idioma',
            selectLanguage: 'Selecionar Idioma',
            active: 'Ativo',
            exec: 'Executar',
            id: 'ID',
            title: 'Título',
            images: 'Imagens',
            defaultDescription: 'Nome Padrão',  
            view: 'Visualizar',
            filterFrom: 'De',
            filterTo: 'Até',
        },

        message: {
            modelInUse: 'Este registro é dependência de outros.',
            saveSuccess: 'Registro salvo com sucesso',
            deleteSuccess: 'Registro excluído com sucesso',
            saveError: 'Erro para salvar o registro',
            confirmDelete: 'Tem certeza que deseja excluir este registro?',
            loginError: 'Erro na tentativa de Login'
        },

        dictionary: {
            language: 'Idioma',
            name: 'Nome',
            namePlural: 'Nome Plural',
            description: 'Descrição',
            aliases: 'Aliases',
        },

        user: {
            title: EnumLocale.PermissionType[PermissionType.Core_User],
            username: 'Usuário',
            password: 'Senha',
            name: 'Nome',
            email: 'E-mail',
            permissionGroup: 'Grupo de Permissão',
        },

        userProfile: {
            title: 'Perfil de Usuário',
            newPassword: 'Nova Senha',
            passwordSaveSuccess: 'Senha alterada com sucesso',
            passwordSaveError: 'Erro para salvar a nova senha',
            editPassword: 'Alterar Senha',
            confirmEditPassword: 'Redefinir Senha',
        },

        currency: {
            title: EnumLocale.PermissionType[PermissionType.Core_Currency],
            name: 'Nome',
            namePlural: 'Nome Plural',
            symbol: 'Símbolo'
        },

        measurementUnit: {
            title: EnumLocale.PermissionType[PermissionType.Core_MeasurementUnit],
            name: 'Nome',
            namePlural: 'Nome Plural',
            symbol: 'Símbolo',
            dimension: 'Dimensão',
            defaultConversionFactor: 'Fator de Conversão Padrão',
            isDefault: 'Padrão',
        },

        permission: {
            type: 'Tipo',
            level: 'Nível',
        },

        permissionGroup: {
            title: EnumLocale.PermissionType[PermissionType.Core_PermissionGroup],
            name: 'Nome',
            permissionType: 'Tipo de Permissão'
        },

        enum: EnumLocale
    },
}
