export enum PermissionType {
    Core_PermissionGroup = 'Core_PermissionGroup',
    Core_User = 'Core_User',
    Core_Currency = 'Core_Currency',
    Core_LogText = 'Core_LogText',
    Core_MeasurementUnit = 'Core_MeasurementUnit',
    Core_Audit = 'Core_Audit',

    Crm_Company = 'Crm_Company',

    Products_ProductTypeConferenceType = 'Products_ProductTypeConferenceType',
    Products_ProductType = 'Products_ProductType',
    Products_Product = 'Products_Product',
    Products_SupplierCompany = 'Products_SupplierCompany',
    Products_SupplierProduct = 'Products_SupplierProduct',

    Stock_StockMovement = 'Stock_StockMovement',
    Stock_StockProductType = 'Stock_StockProductType',
    Stock_TransporterCompany = 'Stock_TransporterCompany',

    Quality_QualityReview = 'Quality_QualityReview',
    Quality_QualityReview_InternalProducts = 'Quality_QualityReview_InternalProducts',
    Quality_QualityReview_OtherCategories = 'Quality_QualityReview_OtherCategories',

    Production_Reactor = 'Production_Reactor',
    Production_ProductionRecipe = 'Production_ProductionRecipe',
    Production_ProductionBatch = 'Production_ProductionBatch',
    Production_ProductionBatch_Execution = 'Production_ProductionBatch_Execution',
    Production_ProductApplication = 'Production_ProductApplication',

    
}
