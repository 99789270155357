import { Component } from '@angular/core';
import { ApiRoute } from '../../enums/api-route';
import { FilterFieldCurrency } from '../../enums/filter-field-currency';
import { Currency } from '../../models/currency';
import { CurrencyForm } from '../../models/forms/currency.form';
import { BaseFilterPage } from '../base-filter-page';
import { BasePageDeps } from '../base-page-deps';
import { PermissionType } from '../../enums/permission-type';

@Component({
    selector: 'app-core-currency-page',
    templateUrl: './currency.page.html',
    styleUrls: ['./currency.page.less']
})
export class CurrencyPage extends BaseFilterPage<Currency, CurrencyForm> {
    constructor(deps: BasePageDeps) {
        super(deps, Currency, CurrencyForm, ApiRoute.currency.filter, ApiRoute.currency.default);
        this.title = this.i18n.t.core.currency.title;
        this.mainPermissionType = PermissionType.Core_Currency;
    }

    createFilter() {
        this.filter.CreateField(
            this.i18n.t.core.currency.name + " | " + this.i18n.t.core.currency.namePlural, 
            FilterFieldCurrency.NAME);
        this.filter.CreateField(this.i18n.t.core.currency.symbol, FilterFieldCurrency.SYMBOL);
    }

    createTable() {
        this.defaultCrudTableActions();

        this.table.Column()
            .Label(this.i18n.t.core.currency.name)
            .OrderBy(FilterFieldCurrency.NAME)
            .Value(x => x.name)
            .Priority(3);

        this.table.Column()
            .Label(this.i18n.t.core.currency.namePlural)
            .OrderBy(FilterFieldCurrency.NAME_PLURAL)
            .Value(x => x.namePlural)
            .Priority(4);

        this.table.Column()
            .Label(this.i18n.t.core.currency.symbol)
            .OrderBy(FilterFieldCurrency.SYMBOL)
            .Value(x => x.symbol);
    }
}
