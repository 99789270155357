<nav>
    <div class="logo" (click)="pageRouteService.goToHome()" [class.opened]="showMenu">
        <img *ngIf="showMenu" src="/assets/img/logo_white.png">
        <img *ngIf="!showMenu"src="/assets/img/favicon_white.svg">
    </div>
    <div class="container">
        <div class="left-icons">
            <button (click)="toggleMenu()">
                <fa-icon [icon]="icon.bars"></fa-icon>
            </button>
        </div>
        <div class="page-title">
            <span>{{ pageRouteService.getPageTitle() }}</span>
        </div>
        <div class="right-icons">
            <!-- <div class="user-identification system-date">
                <div class="info">
                    <div class="name">{{ getSystemDate() }}</div>
                    <div class="type">{{ i18n.t.system.systemDate }}</div>
                </div>
            </div> -->
            <div class="user-identification" *ngIf="user">
                <div class="info">
                    <div class="name">{{ user.name }}</div>
                </div>
            </div>
        </div>
        <div class="right-icons" *ngIf="pageRouteService.currentPage.type == PageType.FILTER">

            <button *ngIf="pageRouteService.currentPage.edit && !pageRouteService.currentPage.hideInclude" (click)="pageRouteService.currentPage.edit()">
                <fa-icon [icon]="icon.add"></fa-icon>
                <span>{{ i18n.t.core.label.add }}</span>
            </button>

            <section *ngFor="let c of pageRouteService.currentPage.headerCustomButtons">
                <button (click)="c.action()" *ngIf="!c.show || c.show()">
                    <fa-icon [icon]="c.icon"></fa-icon>
                    {{ c.label }}
                </button>
            </section>       

            <button *ngIf="pageRouteService.currentPage.filter"
                (click)="pageRouteService.currentPage.filter.toggleFilterArea()">

                <fa-icon [icon]="icon.up" *ngIf="pageRouteService.currentPage.filter.showFilterArea"></fa-icon>
                <span *ngIf="pageRouteService.currentPage.filter.showFilterArea">
                    {{ i18n.t.core.label.hideFilter }}
                </span>

                <fa-icon [icon]="icon.down" *ngIf="!pageRouteService.currentPage.filter.showFilterArea"></fa-icon>
                <span *ngIf="!pageRouteService.currentPage.filter.showFilterArea">
                    {{ i18n.t.core.label.showFilter }}
                </span>

            </button>
        </div>
        <div class="right-icons" *ngIf="pageRouteService.currentPage.type == PageType.DETAIL">
            <section *ngFor="let c of pageRouteService.currentPage.headerCustomButtons">
                <button (click)="c.action()" *ngIf="!c.show || c.show()">
                    <fa-icon [icon]="c.icon"></fa-icon>
                    {{ c.label }}
                </button>
            </section>  
            <button (click)="pageRouteService.currentPage.closeDetails()">
                <fa-icon [icon]="icon.cancel"></fa-icon>
                <span>{{ i18n.t.core.label.cancel }}</span>
            </button>
            <button (click)="pageRouteService.currentPage.save()">
                <fa-icon [icon]="icon.save"></fa-icon>
                <span>{{ i18n.t.core.label.save }}</span>
            </button>
        </div>
        <div class="right-icons" *ngIf="pageRouteService.currentPage.type == PageType.BASE">
            <section *ngFor="let c of pageRouteService.currentPage.headerCustomButtons">
                <button (click)="c.action()" *ngIf="!c.show || c.show()">
                    <fa-icon [icon]="c.icon"></fa-icon>
                    {{ c.label }}
                </button>
            </section>  
        </div>

    </div>
</nav>
<div class="body">
    <div class="menu" [class.opened]="showMenu">
        <div class="page-list">
            <div *ngFor="let group of pageRouteService.pageGroupList" class="group-page-item" 
            [class.opened]="!!group.opened" [class.closed]="!group.opened" >
                <div class="title group-title" (click)="openGroup(group)">
                    <fa-icon [icon]="group.icon"></fa-icon>                   
                    {{ group.title }}
                </div>
                <div class="page-list-area">
                    <div *ngFor="let page of group.pageList" (click)="openPage(page)" class="page-item">
                        <fa-icon [icon]="page.icon"></fa-icon>
                        <span class="title">{{ page.title }}</span>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="fixed-bottom">
            <!-- <div class="page-item" (click)="openMock()">
                <fa-icon [icon]="icon.database"></fa-icon>
                <span class="title">{{ i18n.t.system.mock.title }}</span>
            </div>
            <div class="page-item" (click)="openSystemConfig()">
                <fa-icon [icon]="icon.calendarDay"></fa-icon>
                <span class="title">{{ i18n.t.system.modalTitle }}</span>
            </div> -->
            <div class="version-item">
                v{{ appVersion }}
            </div>
            <!-- <div class="page-item" (click)="showSelectlanguage = true">
                <fa-icon [icon]="icon.language"></fa-icon>
                <span class="title">{{ i18n.t.core.label.language }}</span>
            </div> -->
            <div class="page-item" (click)="logout()">
                <fa-icon [icon]="icon.logout"></fa-icon>
                <span class="title">{{ i18n.t.core.label.logout }}</span>
            </div>
        </div>
    </div>
    <div class="container">
        <router-outlet></router-outlet>
    </div>
</div>

<p-dialog [(visible)]="showSelectlanguage" [modal]="true" [styleClass]="'modal-sm'" [resizable]="false"
    [blockScroll]="true" [closeOnEscape]="true" [header]="i18n.t.core.label.language">
    <div class="body-content">
        <div class="form ui-g">
            <div class="ui-g-12">
                <g-form-input [input]="language"></g-form-input>
            </div>
        </div>
    </div>
    <p-footer class="footer-modal">
        <div class="button-modal">
            <button class="btn left" (click)="showSelectlanguage = false">
                <fa-icon [icon]="icon.cancel"></fa-icon>
                {{ i18n.t.core.label.cancel }}
            </button>

            <button class="btn" (click)="saveLanguage()">
                <fa-icon [icon]="icon.save"></fa-icon>
                {{ i18n.t.core.label.save }}
            </button>
        </div>
    </p-footer>
</p-dialog>
