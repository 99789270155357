import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ApiRoute } from '../enums/api-route';
import { RouteConfig } from '../enums/route-config';
import { ServiceHandler } from '../handlers/service.handler';
import { LoginRequest } from '../models/request/login-request';
import { LoginResponse } from '../models/request/login-response';
import { User } from '../models/user';
import { UserService } from './user.service';

@Injectable()
export class AuthService implements CanActivate {
    constructor(
        private service: ServiceHandler,
        private userService: UserService,
        private router: Router
    ) { }

    login(loginRequest: LoginRequest): Promise<LoginResponse> {
        return this.service.Post(ApiRoute.auth, loginRequest);
    }

    msLogin(token: string): Promise<LoginResponse> {
        return this.service.Post(ApiRoute.msAuth, { token });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise(res => {
            let user = this.userService.getUser();
            if (route.routeConfig.path == RouteConfig.login) {
                this.canNavigateLogin(user, res);
            } else if (route.routeConfig.path.indexOf(RouteConfig.home) == 0) {
                this.canNavigateApp(user, res);
            } else {
                res(true);
            }
        });
    }

    private canNavigateLogin(user: User, res): void {
        if (!user) {
            res(true);
        } else {
            this.service.Get(ApiRoute.auth)
                .then(() => {
                    this.router.navigate([RouteConfig.home]);
                    res(false);
                })
                .catch(() => {
                    res(true);
                    this.userService.logout();
                });
        }
    }

    private canNavigateApp(user: User, res): void {
        if (!user) {
            this.router.navigate([RouteConfig.login]);
            res(false);
        } else {
            this.service.Get(ApiRoute.auth)
                .then(() => {
                    res(true);
                })
                .catch(() => {
                    this.router.navigate([RouteConfig.login]);
                    res(false);
                });
        }
    }
}
