import { Injectable } from "@angular/core";
import { ApiRoute } from '../../core/enums/api-route';
import { ServiceHandler } from '../../core/handlers/service.handler';
import { MeasurementUnit } from "../models/measurement-unit";

@Injectable()
export class MeasurementUnitService {

    constructor(
        private service: ServiceHandler,
    ) { }

    list(): Promise<MeasurementUnit[]> {
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.measurementUnit.list)
                .then(data => {
                    resolve(data.map(x => MeasurementUnit.fromData(x)));
                })
                .catch(err => reject(err));
        });
    }

    convert(value: number, originMeasurementUnit: MeasurementUnit, targetMeasurementUnit: MeasurementUnit): number {
        if (originMeasurementUnit.dimension != targetMeasurementUnit.dimension) {
            throw ("Not possible to convert MeasurementUnit from different dimensions");
        }
        return value * originMeasurementUnit.defaultConversionFactor / targetMeasurementUnit.defaultConversionFactor;
    }
}

