import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AppConfig } from 'src/environments/app-config';
import { RouteConfig } from '../../enums/route-config';
import { LoginRequest } from '../../models/request/login-request';
import { AuthService } from '../../services/auth.service';
import { BasePage } from '../base-page';
import { BasePageDeps } from '../base-page-deps';

@Component({
    selector: 'app-login-page',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.less']
})
export class LoginPage extends BasePage {

    data = new LoginRequest();
    appVersion = AppConfig.version;
    useMs = true;
    showQuickLogin = AppConfig.showQuickLogin;

    constructor(
        deps: BasePageDeps,
        private authService: AuthService,
        private msalService: MsalService
    ) {
        super(deps);
    }

    login() {
        if (!this.data.password || !this.data.username) return;
        this.block.start();
        this.authService.login(this.data)
            .then(data => {
                this.userService.saveToken(data.token);
                return this.userService.updateUserFromServer()
            })
            .then(() => this.router.navigate([RouteConfig.home]))
            .catch(err => {
                this.alert.error(this.i18n.t.core.message.loginError);
            })
            .then(() => this.block.stop())
    }

    msLogin() {
        this.block.start();

        this.msalService.loginPopup({
            scopes: ['openid', 'email', 'profile'],
        })
            .subscribe({
                next: (result) => {
                    // console.log(result);
                    this.authService.msLogin(result.accessToken)
                        .then(data => {
                            this.userService.saveToken(data.token);
                            return this.userService.updateUserFromServer()
                        })
                        .then(() => this.router.navigate([RouteConfig.home]))
                        .catch(err => {
                            this.alert.error(this.i18n.t.core.message.loginError);
                        })
                        .then(() => this.block.stop())
                },
                error: (error) => {
                    console.log(error)
                    this.block.stop()
                }
            });
    }

    togleAuthMode() {
        this.data = new LoginRequest();
        this.useMs = !this.useMs;
    }

    quickLogin() {
        this.data.password = 'admin'
        this.data.username = 'admin'
        this.login();
    }

    quickLoginTest() {
        this.data.password = 'teste'
        this.data.username = 'teste'
        this.login();
    }
}
