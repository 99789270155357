import { BaseEntity } from "../../core/models/base-entity";
import { MeasurementUnit } from "../../core/models/measurement-unit";
import { StockMovementProduct } from "./stock-movement-product";

export class StockProduct extends BaseEntity {
    productId: number;
    product: StockMovementProduct;
    quantity: number;
    measurementUnitId: number;
    measurementUnit: MeasurementUnit;
    batchNumber: string;
    manufacturingDate: Date | null;
    expirationDate: Date | null;

    static fromData(data: any): StockProduct {
        const e: StockProduct = super.fromData(data);
        if (e.product) e.product = StockMovementProduct.fromData(e.product);
        if (e.measurementUnit) e.measurementUnit = MeasurementUnit.fromData(e.measurementUnit);
        if (e.manufacturingDate) e.manufacturingDate = new Date(e.manufacturingDate);
        if (e.expirationDate) e.expirationDate = new Date(e.expirationDate);
        return e;
    }
}


export enum StockProductFilterField {
    Name = 'Name',
}
