import { MeasurementUnitDimension } from "../../core/enums/measurement-unit-dimention";
import { ProductCategory } from "../../core/enums/product-category";
import { BaseEntity } from "../../core/models/base-entity";
import { Currency } from "../../core/models/currency";

export class StockMovementProduct extends BaseEntity {
    name: string;

    manufacturerCompanyId: number;
    manufacturerCompanyName: string;

    productTypeId: number;
    productTypeName: string;
    productCategory: ProductCategory;
    measurementUnitDimension: MeasurementUnitDimension;
    stockMeasurementUnitId: number;
    tagCode: string;
    value: number;
    currency: Currency;

    static fromData(data: any): StockMovementProduct {
        const e: StockMovementProduct = super.fromData(data);
        if (e.currency) e.currency = Currency.fromData(e.currency);
        return e;
    }
}