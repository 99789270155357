<div class="page-content">
    <div class="form ui-g">
        <div class="ui-g-12">
            <g-form-input [input]="form.name"></g-form-input>
        </div>
        <div class="ui-g-12">
            <g-form-input [input]="form.username"></g-form-input>
        </div>
        <div class="ui-g-12">
            <g-form-input [input]="form.email"></g-form-input>
        </div>        
    </div>

    <div class="form ui-g chage-pass">  
        <button (click)="showChangePassword = true" class="btn" *ngIf="!showChangePassword">
            <fa-icon [icon]="icon.key"></fa-icon>
            {{ i18n.t.core.userProfile.editPassword }}
        </button>
        <div class="ui-g-12" *ngIf="showChangePassword">
            <g-form-input [input]="form.password"></g-form-input>            
        </div>
        <div class="ui-g-12" *ngIf="showChangePassword">
            <button (click)="savePassword()" class="btn">
                <fa-icon [icon]="icon.add"></fa-icon>
                {{ i18n.t.core.userProfile.confirmEditPassword }}
            </button>         
        </div>
    </div>
</div>