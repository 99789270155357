import { ChangeDetectorRef, Component } from '@angular/core';
import { Icon } from './modules/core/enums/icon';
import { I18n } from './modules/core/i18n';
import { BasePageDeps } from './modules/core/pages/base-page-deps';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'S3Nano';
  icon = Icon;
  constructor(public i18n: I18n,
    cdRef: ChangeDetectorRef,
    pageDeps: BasePageDeps
  ) {
    pageDeps.cdRef = cdRef;
  }
}
