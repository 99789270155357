import { FormInput } from '../forms/base/form-input';
import { Table } from './table';

export class TableColumn<T> {
    label: string = '';
    orderBy: string;
    width: string;
    priority: number;
    getClassName: (model: T) => string = () => '';
    inputSelector: (form: T) => FormInput<any>;      
    onClick: (model: T) => void = null;      

    getValue: (model: T) => string | number = () => null;

    Label(label: string) {
        this.label = label;
        return this;
    }

    OrderBy(orderBy: string) {
        this.orderBy = orderBy;
        return this;
    }

    Priority(priority: number) {
        this.priority = priority;
        return this;
    }

    Width(width: string) {
        this.width = width;
        return this;
    }

    Value(getValue: (model: T) => string | number) {
        this.getValue = getValue;
        return this;
    }

    StyleClass(getClassName: (model: T) => string = null) {
        this.getClassName = getClassName;
        return this;
    }

    Input(inputSelector: (form: T) => FormInput<any>) {
        this.inputSelector = inputSelector;
        return this;
    }

    OnClick(onClick: (model: T) => void) {
        this.onClick = onClick;
        return this;
    }
}
