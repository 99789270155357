import { BaseEntity } from "./base-entity";

export enum AuditAction {
    Insert = 'Insert',
    Update = 'Update',
    Delete = 'Delete',
}

export class AuditModel extends BaseEntity {
    auditId: number;
    entity: string;
    action: AuditAction;
    modelId: number;
    modelContent: string;
    userId: number;
    userName: string;
    createdDate: Date;
    modelObject: any;
    auditRequestId: string;

    static fromData(data: any) {
        const e: AuditModel = super.fromData(data);
        if (e.modelContent) {
            e.modelObject = JSON.parse(e.modelContent);
            e.modelContent = e.modelContent.ReplaceAll('","', '", "');
        }
        return e;
    }
}

export enum AuditModelFilterField {
    Entity = 'Entity',
    Action = 'Action',
    UserId = 'UserId',
    CreatedDate = 'CreatedDate',
    ModelId = 'ModelId',
    AuditRequestId = 'AuditRequestId',
}

