import { I18n } from '../../../i18n';
import { BasePage } from '../../../pages/base-page';
import { BaseEntity } from '../../base-entity';
import { BaseModel } from '../../base-model';
import { FormInput } from "./form-input";
import { TableForm } from './table-form';


export class BaseForm<TModel extends BaseModel> extends BaseModel {

    model: TModel;
    inputList: FormInput<any>[] = [];
    subFormList: BaseForm<BaseModel>[] = [];
    tableFormList: TableForm<BaseForm<BaseModel>>[] = [];
    i18n = I18n.current;
    getTextTopRow: (form: BaseForm<TModel>) => string | null = f => null;
    hasTextTopRow = false;
    viewMode = false;
    originPage: BasePage;

    Input<TValue>(label: string = '', defaultValue: any = ''): FormInput<TValue> {
        let input = new FormInput<TValue>(label, defaultValue);
        this.inputList.push(input);
        return input;
    }

    SubForm<TForm extends BaseForm<any>>(form: TForm): TForm {
        this.subFormList.push(form as any as BaseForm<BaseEntity>);
        form.originPage = this.originPage;
        return form;
    }

    TableForm<TForm extends TableForm<any>>(form: TForm, formType: any, title?: string): TForm {
        this.tableFormList.push((form as any as TableForm<BaseForm<BaseEntity>>).Form(formType));
        if (!!title) form.Title(title);
        form.originPage = this.originPage;
        return form;
    }

    ShowValidation(showValidation: boolean = true) {
        this.inputList.forEach(x => x.ShowValidation(showValidation));
        this.subFormList.forEach(x => x.ShowValidation(showValidation));
        this.tableFormList.forEach(x => x.ShowValidation(showValidation));
    }

    Model(model: TModel) {
        this.reset(model);
        this.configure();
    }

    TextTopRow<TForm extends BaseForm<any>>(getTextTopRow: (form: TForm) => string) {
        this.getTextTopRow = getTextTopRow;
        this.hasTextTopRow = !!getTextTopRow(this as any);
        return this;
    }

    ViewMode() {
        this.viewMode = true;
        this.inputList.forEach(x => x.viewMode = true);
        this.subFormList.forEach(x => x.ViewMode());
        this.tableFormList.forEach(x => x.ViewMode());
    }

    reset(model: TModel) {
        this.model = model;
        this.inputList = [];
        this.subFormList = [];
        this.tableFormList = [];
    }

    isValid(): boolean {
        return !this.getErrorMessage();
    }

    getErrorMessage(): string {
        for (let i = 0; i < this.inputList.length; i++) {
            let m = this.inputList[i].getErrorMessage();
            if (m) return m;
        }
        for (let i = 0; i < this.subFormList.length; i++) {
            let m = this.subFormList[i].getErrorMessage();
            if (m) return m;
        }
        for (let i = 0; i < this.tableFormList.length; i++) {
            let m = this.tableFormList[i].getErrorMessage();
            if (m) return m;
        }
    }

    configure() { }

    getDTO(baseModel: BaseModel = null): BaseModel {
        return new BaseModel();
    }

    PermissionEdit() {
        return !this.originPage || this.originPage.permissionEdit(); 
    }

    PermissionInclude() {
        return !this.originPage || this.originPage.permissionInclude(); 
    }

    PermissionDelete() {
        return !this.originPage || this.originPage.permissionDelete(); 
    }

}
