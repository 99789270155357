<router-outlet></router-outlet>
<block-ui></block-ui>

<p-confirmDialog width="350" #confimationDialog [closable]="false">
    <p-footer class="confirm-dialog-footer">
        <button (click)="confimationDialog.reject()" class="btn">
            <fa-icon [icon]="icon.no"></fa-icon>
            {{ i18n.t.core.label.no }}
        </button>
        <button (click)="confimationDialog.accept()" class="btn">
            <fa-icon [icon]="icon.yes"></fa-icon>
            {{ i18n.t.core.label.yes }}
        </button>
    </p-footer>
</p-confirmDialog>