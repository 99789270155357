import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { CrmRouteConfig } from "../../crm/crm-route-config";
import { ProductsRouteConfig } from "../../products/products-route-config";
import { QualityRouteConfig } from "../../quality/quality-route-config";
import { StockRouteConfig } from "../../stock/products-route-config";
import { Icon } from "../enums/icon";
import { PermissionType } from '../enums/permission-type';
import { RouteConfig } from "../enums/route-config";
import { I18n } from "../i18n";
import { PageRoute, PageRouteGroup } from '../models/page-route';
import { User } from "../models/user";
import { BasePage } from "../pages/base-page";
import { UserService } from "./user.service";
import { ProductionRouteConfig } from "../../production/production-route-config";
// import { ProductionRouteConfig } from "../../production/enums/production-route-config";

@Injectable()
export class PageRouteService {

    pageList: Array<PageRoute> = [];
    pageGroupList: PageRouteGroup[] = [];
    currentPage: any;
    statePages: BasePage[] = [];

    constructor(
        private i18n: I18n,
        private userService: UserService,
        private router: Router
    ) {
        this.setPages();
    }

    open(page: PageRoute) {
        this.statePages = [];
        this.router.navigate([page.route]);
    }

    goToHome() {
        this.router.navigate([RouteConfig.home]);
    }

    goToLogin() {
        this.router.navigate([RouteConfig.login]);
    }

    setPages() {
        this.pageList = [];
        this.pageGroupList = [];

        let user = this.userService.getUser();
        if (!user) return;

        let group = {
            systemConfig: new PageRouteGroup('Configurações', Icon.cog),
            user: new PageRouteGroup('Usuário', Icon.user),
            registry: new PageRouteGroup('Cadastros', Icon.book),
            stock: new PageRouteGroup('Estoque', Icon.warehouse),
            quality: new PageRouteGroup('Qualidade', Icon.clipboardCheck),
            production: new PageRouteGroup('Produção', Icon.bolt),
        }

        this.appendPage(user, PermissionType.Core_User,
            new PageRoute(this.i18n.t.navigation.core.user,
                RouteConfig.user, Icon.users),
            group.systemConfig);

        this.appendPage(user, PermissionType.Core_PermissionGroup,
            new PageRoute(this.i18n.t.navigation.core.permissionGroup,
                RouteConfig.permissionGroup, Icon.idCard),
            group.systemConfig);

        this.appendPage(user, PermissionType.Core_Currency,
            new PageRoute(this.i18n.t.navigation.core.currency,
                RouteConfig.currency, Icon.money),
            group.systemConfig);

        this.appendPage(user, PermissionType.Core_MeasurementUnit,
            new PageRoute(this.i18n.t.navigation.core.measurementUnit,
                RouteConfig.measurementUnit, Icon.balanceLeft),
            group.systemConfig);

        this.appendPage(user, PermissionType.Core_Audit,
            new PageRoute(this.i18n.t.navigation.core.auditModel,
                RouteConfig.auditModel, Icon.bug),
            group.systemConfig);


        // this.appendPage(user, PermissionType.Core_LogText,
        //     new PageRoute(this.i18n.t.navigation.core.logText,
        //         RouteConfig.logText, Icon.bug));   


        this.appendPage(user, PermissionType.Crm_Company,
            new PageRoute(this.i18n.t.navigation.crm.company,
                CrmRouteConfig.module + '/' + CrmRouteConfig.company, Icon.store),
            group.registry);

        this.appendPage(user, PermissionType.Products_SupplierCompany,
            new PageRoute(this.i18n.t.navigation.products.supplierCompany,
                ProductsRouteConfig.module + '/' + ProductsRouteConfig.supplierCompany, Icon.dolly),
            group.registry);

        this.appendPage(user, PermissionType.Stock_TransporterCompany,
            new PageRoute(this.i18n.t.navigation.stock.transporterCompany,
                StockRouteConfig.module + '/' + StockRouteConfig.transporterCompany, Icon.truck),
            group.registry);

        this.appendPage(user, PermissionType.Products_ProductTypeConferenceType,
            new PageRoute(this.i18n.t.navigation.products.productTypeConferenceType,
                ProductsRouteConfig.module + '/' + ProductsRouteConfig.productTypeConferenceType, Icon.tasks),
            group.registry);

        this.appendPage(user, PermissionType.Products_ProductType,
            new PageRoute(this.i18n.t.navigation.products.productType,
                ProductsRouteConfig.module + '/' + ProductsRouteConfig.productType, Icon.atom),
            group.registry);

        this.appendPage(user, PermissionType.Products_Product,
            new PageRoute(this.i18n.t.navigation.products.product,
                ProductsRouteConfig.module + '/' + ProductsRouteConfig.product, Icon.flask),
            group.registry);

        this.appendPage(user, PermissionType.Products_SupplierProduct,
            new PageRoute(this.i18n.t.navigation.products.supplierProduct,
                ProductsRouteConfig.module + '/' + ProductsRouteConfig.supplierProduct, Icon.dollyFlatbed),
            group.registry);

        this.appendPage(user, PermissionType.Production_Reactor,
            new PageRoute(this.i18n.t.navigation.production.reactor,
                ProductionRouteConfig.module + '/' + ProductionRouteConfig.reactor, Icon.pestle),
            group.registry);



        this.appendPage(user, PermissionType.Stock_StockMovement,
            new PageRoute(this.i18n.t.navigation.stock.stockMovement,
                StockRouteConfig.module + '/' + StockRouteConfig.stockMovement, Icon.exchangeAlt),
            group.stock);

        this.appendPage(user, PermissionType.Stock_StockProductType,
            new PageRoute(this.i18n.t.navigation.stock.stockproductType,
                StockRouteConfig.module + '/' + StockRouteConfig.stockproductType, Icon.warehouse),
            group.stock);


        this.appendPage(user, PermissionType.Quality_QualityReview,
            new PageRoute(this.i18n.t.navigation.quality.qualityReview,
                QualityRouteConfig.module + '/' + QualityRouteConfig.qualityReview, Icon.clipboardCheck),
            group.quality);

        this.appendPage(user, PermissionType.Quality_QualityReview_InternalProducts,
            new PageRoute(this.i18n.t.navigation.quality.qualityReviewInternalProducts,
                QualityRouteConfig.module + '/' + QualityRouteConfig.qualityReviewInternalProducts, Icon.clipboardCheck),
            group.quality);

        this.appendPage(user, PermissionType.Quality_QualityReview_OtherCategories,
            new PageRoute(this.i18n.t.navigation.quality.qualityReviewOtherCategories,
                QualityRouteConfig.module + '/' + QualityRouteConfig.qualityReviewOtherCategories, Icon.clipboardCheck),
            group.quality);

        

        this.appendPage(user, PermissionType.Production_ProductionRecipe,
            new PageRoute(this.i18n.t.navigation.production.productionRecipe,
                ProductionRouteConfig.module + '/' + ProductionRouteConfig.productionRecipe, Icon.book),
            group.production);

        this.appendPage(user, PermissionType.Production_ProductionBatch,
            new PageRoute(this.i18n.t.navigation.production.productionBatch,
                ProductionRouteConfig.module + '/' + ProductionRouteConfig.productionBatch, Icon.bolt),
            group.production);

        this.appendPage(user, PermissionType.Production_ProductionBatch_Execution,
            new PageRoute(this.i18n.t.navigation.production.productionBatchExecution,
                ProductionRouteConfig.module + '/' + ProductionRouteConfig.productionBatchExecution, Icon.fire),
            group.production);

        this.appendPage(user, PermissionType.Production_ProductApplication,
            new PageRoute(this.i18n.t.navigation.production.productApplication,
                ProductionRouteConfig.module + '/' + ProductionRouteConfig.productApplication, Icon.bookmark),
            group.production);


        this.appendPage(user, null,
            new PageRoute(this.i18n.t.core.userProfile.title,
                RouteConfig.userProfile, Icon.user),
            group.user);
    }

    appendPage(user: User, permissionType: PermissionType, page: PageRoute, group: PageRouteGroup) {
        if (permissionType == null || this.userService.hasPermission(user, permissionType)) {           
            if (!this.pageGroupList.Any(x => x.title == group.title)) {
                this.pageGroupList.push(group);
            }
            this.pageList.push(page);
            group.pageList.push(page);
        }
    }

    getPageTitle() {
        return this.currentPage ? this.currentPage.title : '';
    }

    setCurrentPage(page: BasePage) {
        var path = window.location.pathname;
        if(path != '/'){
            path = path.substring(1);
            setTimeout(() => {
                const currentGroup = this.pageGroupList.First(x => x.pageList.Any(y => path == y.route));
                if(!!currentGroup){
                    currentGroup.opened = true;
                }
            }, 0)
        }  

        if(this.currentPage == page) return;
        let recoveredPage = this.statePages.find(p => p.constructor.name == page.constructor.name);
        if (recoveredPage) {
            page = Object.assign(page, recoveredPage);
            page.recoveredState = true;
        }
        this.currentPage = page;
    }

    navigate(originPage: BasePage, ...params: Array<string | number>) {
        this.statePages.push(originPage)
        this.router.navigate(params);
    }

}
