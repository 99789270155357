import { Component, Input } from "@angular/core";
import { BaseFilterPage } from '../../pages/base-filter-page';
import { BaseEntity } from '../../models/base-entity';
import { BaseForm } from '../../models/forms/base/base-form';
import { I18n } from '../../i18n';
import { Icon } from '../../enums/icon';


@Component({
    selector: 'g-form-dialog',
    templateUrl: './form-dialog.component.html',
    styleUrls: ['./form-dialog.component.less']
})
export class FormDialogComponent {
    @Input() page: BaseFilterPage<BaseEntity, BaseForm<BaseEntity>>;
    @Input() styleClass: string = 'modal-md'; // modal-sm, modal-md, modal-lg, modal-xg, modal-xl
    @Input() title: string = '';

    icon = Icon;
    constructor(public i18n: I18n) { }

    showDelete() {
        return !this.page.hideDelete && this.page.form.model && this.page.form.model.id;
    }

    showSave() {
        return !this.page.hideSave;
    }

    closeDetails() {
        this.page.closeDetails();
    }

    save() {
        this.page.save();
    }

    delete() {
        this.page.delete(this.page.form.model);
    }

    getCancelButtonLabel() {
        return this.i18n.t.core.label.cancel;
    }

}

