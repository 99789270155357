import { BaseModel } from '../base-model';
import { Table } from './table';

export class LocalTable<T extends BaseModel> extends Table<T> {
    public dataRaw: Array<T> = [];

    Data(data: T[]) {
        this.dataRaw = data;
        this.sliceData();
    }

    Add(item: T) {
        this.dataRaw.push(item);
        this.sliceData();
    }

    Remove(item: T) {
        this.dataRaw.RemoveFirstByProp('guid', item.guid);
        this.sliceData();
    }

    ForEach(forEach: (form: T) => void) {
        this.dataRaw.forEach(x => forEach(x));
    }

    private sliceData() {
        this.data = this.dataRaw;
    }
}