import { Injectable } from "@angular/core";
import { ApiRoute } from '../../core/enums/api-route';
import { ServiceHandler } from '../../core/handlers/service.handler';
import { PermissionGroup } from "../models/permission-group";

@Injectable()
export class PermissionGroupService {

    constructor(
        private service: ServiceHandler,
    ) { }

    list(): Promise<PermissionGroup[]> {
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.permissionGroup.list)
                .then(data => {
                    resolve(data.map(x => PermissionGroup.fromData(x)));
                })
                .catch(err => reject(err));
        });
    }
}