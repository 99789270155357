<g-filter [filter]="filter"></g-filter>

<div class="page-content">
    <g-table [filter]="filter" [table]="table"></g-table>
</div>


<g-form-dialog [page]="this" [title]="title" [styleClass]="'modal-xl'">
    <div class="form ui-g">
        <div class="ui-g-6" *ngIf="showColumns">
            <g-form-input [input]="showColumns"></g-form-input>
        </div>
        <div class="ui-g-12" *ngIf="compareTable">
            <g-local-table [table]="compareTable"></g-local-table>
        </div>
    </div>
</g-form-dialog>