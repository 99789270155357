import { Language } from '../../enums/language';
import { MeasurementUnitDimension } from '../../enums/measurement-unit-dimention';
import { Month } from '../../enums/month';
import { PermissionLevel } from '../../enums/permission-level';
import { PermissionType } from '../../enums/permission-type';
import { WeekDay } from '../../enums/week-day';
import { ProductCategory } from "../../enums/product-category";
import { StockMovementType } from '../../enums/stock-movement-type';
import { QualityReviewResult } from '../../enums/quality-review-result';
import { CompanyShippingType } from '../../enums/company-shipping-type';

const language = {};
language[Language.EN] = 'Inglês';
language[Language.PTBR] = 'Português';

const weekDay = {};
weekDay[WeekDay.SUNDAY] = 'Domingo';
weekDay[WeekDay.MONDAY] = 'Segunda';
weekDay[WeekDay.TUESDAY] = 'Terça';
weekDay[WeekDay.WEDNESDAY] = 'Quarta';
weekDay[WeekDay.THURSDAY] = 'Quinta';
weekDay[WeekDay.FRIDAY] = 'Sexta';
weekDay[WeekDay.SATURDAY] = 'Sábado';

const month = {};
month[Month.JANUARY] = 'Janeiro';
month[Month.FEBRUARY] = 'Fevereiro';
month[Month.MARCH] = 'Março';
month[Month.APRIL] = 'Abril';
month[Month.MAY] = 'Maio';
month[Month.JUNE] = 'Junho';
month[Month.JULY] = 'Julho';
month[Month.AUGUST] = 'Agosto';
month[Month.SEPTEMBER] = 'Setembro';
month[Month.OCTOBER] = 'Outubro';
month[Month.NOVEMBER] = 'Novembro';
month[Month.DECEMBER] = 'Dezembro';

const permissionlevel = {};
permissionlevel[PermissionLevel.Read] = 'Leitura';
permissionlevel[PermissionLevel.Edit] = 'Edição';
permissionlevel[PermissionLevel.Include] = 'Inclusão';
permissionlevel[PermissionLevel.Delete] = 'Exclusão';

const permissionType = {};
permissionType[PermissionType.Core_PermissionGroup] = 'Grupos de Permissão';
permissionType[PermissionType.Core_User] = 'Usuários';
permissionType[PermissionType.Core_Currency] = 'Moedas';
permissionType[PermissionType.Core_LogText] = 'DB Logs';
permissionType[PermissionType.Core_MeasurementUnit] = 'Unidades de Medida';
permissionType[PermissionType.Core_Audit] = 'Auditoria de Dados';
permissionType[PermissionType.Crm_Company] = 'Empresas';
permissionType[PermissionType.Products_SupplierCompany] = 'Fornecedores';
permissionType[PermissionType.Products_SupplierProduct] = 'Controle Fornecedores';
permissionType[PermissionType.Products_ProductType] = 'Tipos de Produto';
permissionType[PermissionType.Products_ProductTypeConferenceType] = 'Tipos de Análises';
permissionType[PermissionType.Products_Product] = 'Produtos';
permissionType[PermissionType.Stock_StockMovement] = 'Movimentações';
permissionType[PermissionType.Stock_StockProductType] = 'Estoque';
permissionType[PermissionType.Stock_TransporterCompany] = 'Transportadoras';
permissionType[PermissionType.Quality_QualityReview] = 'Análises de Qualidade';
permissionType[PermissionType.Quality_QualityReview_InternalProducts] = 'Análises de Produtos';
permissionType[PermissionType.Quality_QualityReview_OtherCategories] = 'Análises de Entradas';
permissionType[PermissionType.Production_Reactor] = 'Reatores';
permissionType[PermissionType.Production_ProductionRecipe] = 'Formulação de Produtos';
permissionType[PermissionType.Production_ProductionBatch] = 'Ordens de Produção';
permissionType[PermissionType.Production_ProductionBatch_Execution] = 'Produção';
permissionType[PermissionType.Production_ProductApplication] = 'Aplicações';





const measurementUnitDimension = {};
measurementUnitDimension[MeasurementUnitDimension.Mass] = 'Massa';
measurementUnitDimension[MeasurementUnitDimension.Length] = 'Comprimento';
measurementUnitDimension[MeasurementUnitDimension.Area] = 'Área';
measurementUnitDimension[MeasurementUnitDimension.Time] = 'Tempo';
measurementUnitDimension[MeasurementUnitDimension.Unit] = 'Unidade';
measurementUnitDimension[MeasurementUnitDimension.Volume] = 'Volume';



const productCategory = {};
productCategory[ProductCategory.Feedstock] = 'Matéria Prima';
productCategory[ProductCategory.Glassware] = 'Vridraria';
productCategory[ProductCategory.Encapsulated] = 'Encapsulado';
productCategory[ProductCategory.Packaging] = 'Embalagem';
productCategory[ProductCategory.Consumables] = 'Consumível';
productCategory[ProductCategory.InternalProduct] = 'Produto Interno';
productCategory[ProductCategory.Others] = 'Outros';


const stockMovementType = {};
stockMovementType[StockMovementType.Input] = 'Entrada';
stockMovementType[StockMovementType.Output] = 'Saída';


const qualityReviewResult = {};
qualityReviewResult[QualityReviewResult.Approved] = 'Conforme';
qualityReviewResult[QualityReviewResult.PartialApproved] = 'Parcialmente Conforme';
qualityReviewResult[QualityReviewResult.Rejected] = 'Não Conforme';

const companyShippingType = {};
companyShippingType[CompanyShippingType.Fob] = 'FOB';
companyShippingType[CompanyShippingType.Cif] = 'CIF';


export const EnumLocale = {
    Language: language,
    WeekDay: weekDay,
    Month: month,
    PermissionLevel: permissionlevel,
    PermissionType: permissionType,
    MeasurementUnitDimension: measurementUnitDimension,
    ProductCategory: productCategory,
    StockMovementType: stockMovementType,
    QualityReviewResult: qualityReviewResult,
    CompanyShippingType: companyShippingType
}


