import { BaseEntity } from "./base-entity";
import { PermissionGroup } from "./permission-group";

export class PermissionGroupUser extends BaseEntity {
    permissionGroupId: number;
    permissionGroup: PermissionGroup;

    static fromData(data: any) {
        const e: PermissionGroupUser = super.fromData(data);
        if (e.permissionGroup) e.permissionGroup = PermissionGroup.fromData(e.permissionGroup);
        return e;
    }

}
