<section>
    <div class="button-div">
        <div class="title">
            {{ !!table ? table.title : '' }}
        </div>
        <button (click)="add()" class="btn" *ngIf="table && table.canAddLines">
            <fa-icon [icon]="icon.add"></fa-icon>
            {{ i18n.t.core.label.add  }}
        </button>
    </div>
    <div *ngIf="table?.description" class="table-description">
        {{ table.description }}
    </div>
    <g-local-table [table]="table" [showTitle]="false"></g-local-table>
</section>