import { Injectable } from "@angular/core";
import { ApiRoute } from "../enums/api-route";
import { Language } from "../enums/language";
import { ServiceHandler } from "../handlers/service.handler";

@Injectable()
export class UtilsService {

    constructor(
        private service: ServiceHandler,
    ) { }

    // translate(sourceLanguage: Language, targetLanguage: Language, texts: string[]): Promise<string[]> {
    //     let text = texts.join(";;");
    //     return this.service.Get(`${ApiRoute.utils.translate}?sourceLanguage=${sourceLanguage}&targetLanguage=${targetLanguage}&text=${text}`);
    // }
}