<g-filter [filter]="filter"></g-filter>

<div class="page-content">
    <g-table [filter]="filter" [table]="table"></g-table>
</div>


<g-form-dialog [page]="this" [title]="title">
    <div class="form ui-g">
        <div class="ui-g-12">
            <g-form-input [input]="form.name"></g-form-input>
        </div>
        <div class="ui-g-12">
            <g-form-input [input]="form.username"></g-form-input>
        </div>
        <div class="ui-g-12">
            <g-form-input [input]="form.email"></g-form-input>
        </div>
        <div class="ui-g-12">
            <g-form-input [input]="form.password"></g-form-input>
        </div>

        <div class="ui-g-12">
            <g-table-form [table]="form.permissionGroupUserTableForm"></g-table-form>
        </div> 
    </div>
</g-form-dialog>