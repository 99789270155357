import { Icon } from '../../enums/icon';
import { PermissionGroup } from '../permission-group';
import { BaseForm } from './base/base-form';
import { FormInput } from './base/form-input';
import { TableForm } from './base/table-form';
import { PermissionForm } from './permission.form';

export class PermissionGroupForm extends BaseForm<PermissionGroup> {
    name: FormInput<string>;
    permissionTableForm: TableForm<PermissionForm>;
    configure() {
        this.name = this.Input<string>(this.i18n.t.core.permissionGroup.name).Required();

        this.permissionTableForm = this.TableForm(this.createPermissionTableForm(), PermissionForm);

        if (this.model) {
            this.name.SetValue(this.model.name);
            this.permissionTableForm.ModelData(this.model.permissionList, true);
        } else {
            this.permissionTableForm.CreateLine();

        }
    }

    createPermissionTableForm() {
        let form = new TableForm<PermissionForm>();

        if(this.PermissionEdit()) form.Action(Icon.delete, item => form.Remove(item));

        form.Column()
            .Label(this.i18n.t.core.permission.type)
            .Input(x => x.type);

        form.Column()
            .Label(this.i18n.t.core.permission.level)
            .Input(x => x.level);

        return form;
    }

    getDTO(): PermissionGroup {
        let dto = new PermissionGroup();
        dto.name = this.name.value;
        dto.permissionList = this.permissionTableForm.getDTOList();
        if (this.model) dto.id = this.model.id;
        return dto;
    }
}