<p-dialog [(visible)]="page.showDetail" [modal]="true" [styleClass]="styleClass" [resizable]="false"
    [blockScroll]="true" [closeOnEscape]="true" [header]="title">

    <div class="body-content">
        <ng-content></ng-content>
    </div>

    <p-footer class="footer-modal">

        <div class="button-modal">
            <button class="btn left" (click)="delete()" *ngIf="showDelete()">
                <fa-icon [icon]="icon.delete"></fa-icon>
                {{ i18n.t.core.label.delete }}
            </button>

            <section class="custom-buttons" *ngFor="let c of page.customButtons">
                <button class="btn" (click)="c.action()" *ngIf="!c.show || c.show()">
                    <fa-icon [icon]="c.icon"></fa-icon>
                    {{ c.label }}
                </button>
            </section>
            

            <button class="btn" (click)="closeDetails()">
                <fa-icon [icon]="icon.cancel"></fa-icon>
                {{ i18n.t.core.label.cancel }}
            </button>


            <button class="btn" (click)="save()" *ngIf="showSave()">
                <fa-icon [icon]="icon.save"></fa-icon>
                {{ i18n.t.core.label.save  }}
            </button>
        </div>
    </p-footer>
</p-dialog>