import { Injectable } from "@angular/core";
import { ApiRoute } from "../enums/api-route";
import { PermissionLevel } from "../enums/permission-level";
import { PermissionType } from "../enums/permission-type";
import { StorageTable } from "../enums/storage-table";
import { ServiceHandler } from "../handlers/service.handler";
import { User } from "../models/user";
import { BaseStorageService } from "./base-storage.service";

@Injectable()
export class UserService extends BaseStorageService<User>{

    constructor(
        private service: ServiceHandler,
    ) { super(StorageTable.USER, User) }

    saveToken(token: string) {
        let user = new User();
        user.token = token;
        this.DeleteTable();
        this.Save(user);
    }

    getUser(): User {
        let l = this.List();
        if (l.length == 0) return null;
        return l[0];
    }

    logout() {
        this.DeleteTable();
    }

    updateUserFromServer(): Promise<User> {
        return new Promise((resolve, reject) => {
            this.service.Get(ApiRoute.user.default).then(data => {
                let user = this.getUser();
                user = Object.assign(user, data);
                this.Save(user);
                resolve(user);
            }).catch(err => {
                reject(err);
            });
        });
    }

    hasPermission(user: User, permissionType: PermissionType, permissionLevel: PermissionLevel = PermissionLevel.Read): boolean {
        let permissionTypesNumber = {};
        permissionTypesNumber[PermissionLevel.Read] = 1;
        permissionTypesNumber[PermissionLevel.Edit] = 2;
        permissionTypesNumber[PermissionLevel.Include] = 3;
        permissionTypesNumber[PermissionLevel.Delete] = 4;

        return user.permissionGroupUsers.Any(
            x => x.permissionGroup.permissionList.Any(
                p => p.type == permissionType && permissionTypesNumber[p.level] >= permissionTypesNumber[permissionLevel]));
    }

    saveProfile(name: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.service.Put(ApiRoute.user.profile, { name })
                .then(() => this.updateUserFromServer())
                .then(() => {
                    resolve();
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    savePassword(password: string): Promise<void> {
        return this.service.Put(ApiRoute.user.password, { password });
    }
}