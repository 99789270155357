import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BlockUIModule } from 'ng-block-ui';
import { ToastrModule } from 'ngx-toastr';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputMaskModule } from 'primeng/inputmask';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { TabViewModule } from 'primeng/tabview';
import { ConfirmationService } from 'primeng/api';
import { FilterComponent } from './components/filter/filter.component';
import { FormDialogComponent } from './components/form-dialog/form-dialog.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { TableComponent } from './components/table/table.component';
import { LocalTableComponent } from './components/local-table/local-table.component';
import { TableFormComponent } from './components/table-form/table-form.component';
import { LabelInfoComponent } from './components/label-info/label-info.component';


let primeModules = [
    CalendarModule,
    ConfirmDialogModule,
    DialogModule,
    InputMaskModule,
    MultiSelectModule,
    PaginatorModule,
    TableModule,
    TooltipModule,
    AutoCompleteModule,
    TabViewModule
];

let imports = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BlockUIModule,
    ToastrModule,
    FontAwesomeModule,
    // NgxJsonViewModule,
    // NgxCurrencyModule,
];

let declarations = [
    FormInputComponent,
    TableComponent,
    FilterComponent,
    PageTitleComponent,
    FormDialogComponent,
    LocalTableComponent,
    TableFormComponent,
    LabelInfoComponent,
];

@NgModule({
    declarations: [...declarations],
    imports: [
        ...primeModules,
        ...imports
    ],
    exports: [
        ...primeModules,
        ...imports,
        ...declarations
    ],
    providers: [ConfirmationService]
})
export class CoreModule { }