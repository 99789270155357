import { PermissionLevel } from '../../enums/permission-level';
import { PermissionGroup } from '../permission-group';
import { PermissionGroupUser } from '../permission-group-user';
import { BaseForm } from './base/base-form';
import { FormInput } from './base/form-input';
import { FormInputOptions } from './base/form-input-options';

export class PermissionGroupUserForm extends BaseForm<PermissionGroupUser> {
    permissionGroupId: FormInput<string>;

    permissionGroups: PermissionGroup[] = [];

    configure() {
        this.permissionGroupId = this.Input<PermissionLevel>(this.i18n.t.core.permission.level)
            .Options(this.permissionGroups.map(x => new FormInputOptions(x.id, x.name)))
            .Required();

        if (this.model) {
            this.permissionGroupId.SetValue(this.model.permissionGroupId);
        }
    }

    getDTO(): PermissionGroupUser {
        let dto = new PermissionGroupUser();
        dto.permissionGroupId = this.permissionGroupId.getNumberValue();
        if (this.model) dto.id = this.model.id;
        return dto;
    }
}