import { Component, Input } from "@angular/core";
import { I18n } from '../../i18n';
import { BaseModel } from '../../models/base-model';
import { LocalTable } from '../../models/table/local-table';
import { TableColumn } from '../../models/table/table-column';

@Component({
    selector: 'g-local-table',
    templateUrl: './local-table.component.html',
    styleUrls: ['./local-table.component.less']
})
export class LocalTableComponent<T extends BaseModel> {

    @Input() table: LocalTable<T>;
    @Input() showTitle: boolean = true;
    // @ViewChild('paginator') paginator: Paginator;

    constructor(public i18n: I18n) { }

    sort(event) {

    }

    getActionsWidth() {
        return (this.table.actionList.length * 50) + 'px';
    }

    getPriorityClass(column: TableColumn<T>) {
        let cl = '';
        if (column.priority && column.priority > 5) {
            cl += ' ui-p-6';
        } else if (column.priority && column.priority > 0) {
            cl += ' ui-p-' + column.priority;
        }
        return cl;
    }

    getClassName(column: TableColumn<T>, item: T) {
        let className = column.getClassName(item)
        if (column.inputSelector) {
            let input = column.inputSelector(item);           
            if (input.showValidation && input.getErrorMessage()) {
                className += ' invalid';
            }
        }
        return className;
    }

    getColumnSpan(): number {
        return this.table.columnList.length + (this.table.actionList.length > 0 ? 1 : 0);
    }
}