import { Component, Input } from '@angular/core';
import { TableForm } from '../../models/forms/base/table-form';
import { BaseForm } from '../../models/forms/base/base-form';
import { I18n } from '../../i18n';
import { Icon } from '../../enums/icon';

@Component({
    selector: 'g-table-form',
    templateUrl: './table-form.component.html',
    styleUrls: ['./table-form.component.less']
})
export class TableFormComponent {
    @Input() table: TableForm<BaseForm<any>>;
    icon = Icon;
    constructor(public i18n: I18n){  }

    add() {
        this.table.CreateLine();
    }
}