import { Icon } from "../../enums/icon";
import { PermissionGroup } from "../permission-group";
import { User } from "../user";
import { BaseForm } from "./base/base-form";
import { FormInput } from "./base/form-input";
import { FormInputType } from "./base/form-input-type";
import { TableForm } from './base/table-form';
import { PermissionGroupUserForm } from "./permission-group-user.form";

export class UserForm extends BaseForm<User> {
    name: FormInput<string>;
    username: FormInput<string>;
    email: FormInput<string>;
    password: FormInput<string>;

    permissionGroupUserTableForm: TableForm<PermissionGroupUserForm>;

    permissionGroups: PermissionGroup[] = [];

    configure() {
        this.name = this.Input<string>(this.i18n.t.core.user.name).Required();
        this.username = this.Input<string>(this.i18n.t.core.user.username).Required();
        this.email = this.Input<string>(this.i18n.t.core.user.email).Required();
        this.password = this.Input<string>(this.i18n.t.core.user.password).Type(FormInputType.PASSWORD);


        this.permissionGroupUserTableForm = this.TableForm(this.createPermissionGroupUserTableForm(), PermissionGroupUserForm);

        if (this.model) {
            this.name.SetValue(this.model.name);
            this.username.SetValue(this.model.username);
            this.email.SetValue(this.model.email);
            this.permissionGroupUserTableForm.ModelData(this.model.permissionGroupUsers, true);
        }
    }

    getDTO(): User {
        let dto = new User();
        dto.name = this.name.value;
        dto.username = this.username.value;
        dto.email = this.email.value;
        dto.password = this.password.value;
        dto.permissionGroupUsers = this.permissionGroupUserTableForm.getDTOList();
        if (this.model) dto.id = this.model.id;
        return dto;
    }

    createPermissionGroupUserTableForm() {       
        let form = new TableForm<PermissionGroupUserForm>();

        form.resolveDependencies = (f) => {
            f.permissionGroups = this.permissionGroups;
        }

        if(this.PermissionEdit()) form.Action(Icon.delete, item => form.Remove(item));

        form.Column()
            .Label(this.i18n.t.core.user.permissionGroup)
            .Input(x => x.permissionGroupId);

        return form;
    }

}