import { OnInit } from "@angular/core";
import { PageType } from "../enums/page-type";
import { BaseEntity } from "../models/base-entity";
import { BaseForm } from "../models/forms/base/base-form";
import { BasePage } from "./base-page";
import { BasePageDeps } from "./base-page-deps";


export class BaseDetailPage<TModel extends BaseEntity, TForm extends BaseForm<TModel>>
    extends BasePage implements OnInit {

    form: TForm;
    successMessage;
    deleteSuccess;
    errorMessageMap: any;
    hideDelete = false;
    hideInclude = false;
    hideSave = false;

    constructor(
        deps: BasePageDeps,
        private TModelClass: any,
        protected TFormClass: any,
        public defaultRoute: string
    ) {
        super(deps);
        this.type = PageType.DETAIL;
        if (!this.recoveredState) {
            this.form = this.createForm();
            this.form.originPage = this;
            this.successMessage = this.i18n.t.core.message.saveSuccess;
            this.deleteSuccess = this.i18n.t.core.message.deleteSuccess;
            this.errorMessageMap = {};
        }
    }

    createForm(): TForm {
        var form = new this.TFormClass();
        form.originPage = this;
        return form;
    }

    checkPermissions() {
        if (!this.permissionDelete()) this.hideDelete = true;
        if (!this.permissionInclude()) this.hideInclude = true;
        if (!this.permissionEdit()) this.hideSave = true;
    }

    ngOnInit() {
        if (this.recoveredState) return this.recoveredInit();
        this.block.start();
        this.loadScreenDeps()
            .then(() => { this.checkPermissions(); })
            .catch(err => { })
            .then(() => this.block.stop());
        
    }

    recoveredInit() {

    }

    loadScreenDeps(): Promise<void> {
        return new Promise(r => r());
    }

    save() {
        this.form.ShowValidation(true);
        if (this.form.isValid()) {
            this.block.start();
            let request = this.form.model && this.form.model.id ? 'Put' : 'Post';
            this.service[request](this.defaultRoute, this.form.getDTO())
                .then(() => {
                    this.afterSave();
                    this.alert.success(this.successMessage);
                })
                .catch(err => {
                    this.handleRequestError(err);
                })
                .then(() => this.block.stop());
        }
    }

    afterSave() {
        this.closeDetails();
    }

    protected handleRequestError(error) {
        return this.service.handleRequestError(error, this.errorMessageMap, this.i18n, this.alert);
    }

    delete(model: TModel) {
        this.alert.confirm(this.i18n.t.core.message.confirmDelete, true)
            .then(() => {
                this.block.start();
                this.service.Delete(this.defaultRoute + '/' + model.id)
                    .then(() => {
                        this.afterSave();
                        this.alert.success(this.deleteSuccess);
                    })
                    .catch(err => {
                        this.handleRequestError(err);
                    })
                    .then(() => this.block.stop());
            })
            .catch(() => { })
    }

    closeDetails() {
        window.history.back();
    }

    formDeps(form: TForm): TForm { return form; }
}