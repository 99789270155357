<g-filter [filter]="filter"></g-filter>

<div class="page-content">
    <g-table [filter]="filter" [table]="table"></g-table>
</div>

<g-form-dialog [page]="this" [title]="title" [styleClass]="'modal-md'">
    <div class="form ui-g">
        <div class="ui-lg-12">
            <g-form-input [input]="form.name"></g-form-input>
        </div>
        <div class="ui-g-12">
            <g-table-form [table]="form.permissionTableForm"></g-table-form>
        </div> 
    </div>
</g-form-dialog>