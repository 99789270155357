
import { TableComponent } from '../../components/table/table.component';
import { StorageHandler } from '../../handlers/storage.handler';
import { TableColumn } from "./table-column";
import { TableColumnAction } from "./table-column-action";


export class Table<T> {
    guid = StorageHandler.Guid();
    data: Array<T> = [];
    columnList: TableColumn<T>[] = [];
    actionList: TableColumnAction<T>[] = [];
    title: string;
    description: string;

    tableComponent: TableComponent<T>;
    showSelectionInput = false;
    selectedData: Array<T> = [];

    Title(title: string) {
        this.title = title;
        return this;
    }

    SelectionInput(showSelectionInput = true) {
        this.showSelectionInput = showSelectionInput;
        return this;
    }

    Description(description) {
        this.description = description;
        return this;
    }

    Action(icon, action: (item: T) => void): TableColumnAction<T> {
        let actionIcon = new TableColumnAction<T>(icon, action);
        this.actionList.push(actionIcon);
        return actionIcon;
    }

    Column(): TableColumn<T> {
        let column = new TableColumn<T>();
        this.columnList.push(column);
        return column;
    }

    GetSelectedData(): Array<T> {
        return this.selectedData;
    }

    CleanSelection() {
        if (this.tableComponent) {
            this.tableComponent.masterSelection.SetValue(false);
        }
    }
}