import { Component } from '@angular/core';
import { ApiRoute } from 'src/app/modules/core/enums/api-route';
import { BaseFilterPage } from 'src/app/modules/core/pages/base-filter-page';
import { BasePageDeps } from 'src/app/modules/core/pages/base-page-deps';
import { PermissionType } from '../../enums/permission-type';
import { FormInputOptions } from '../../models/forms/base/form-input-options';
import { FormInputType } from '../../models/forms/base/form-input-type';
import { PermissionGroupForm } from '../../models/forms/permission-group.form';
import { PermissionGroup, PermissionGroupFilterField } from '../../models/permission-group';

@Component({
    selector: 'app-permission-group-page',
    templateUrl: './permission-group.page.html',
    styleUrls: ['./permission-group.page.less']
})
export class PermissionGroupPage extends BaseFilterPage<PermissionGroup, PermissionGroupForm> {
    constructor(deps: BasePageDeps) {
        super(deps, PermissionGroup, PermissionGroupForm, ApiRoute.permissionGroup.filter, ApiRoute.permissionGroup.default);
        this.title = this.i18n.t.core.permissionGroup.title;
        this.mainPermissionType = PermissionType.Core_PermissionGroup;
    }

    createFilter() {
        this.filter.CreateField(this.i18n.t.core.permissionGroup.name, PermissionGroupFilterField.Name);
        let permissionTypeFilter = this.filter.CreateField(this.i18n.t.core.permissionGroup.permissionType, PermissionGroupFilterField.PermissionType)
            .Options(FormInputOptions.fromEnum(PermissionType, this.i18n.t.core.enum.PermissionType))
            .Type(FormInputType.FILTER_SELECT)

        permissionTypeFilter.options = permissionTypeFilter.options.OrderBy(x => x.label);
    }

    createTable() {
        this.defaultCrudTableActions();

        this.table.Column()
            .Label(this.i18n.t.core.permissionGroup.name)
            .OrderBy(PermissionGroupFilterField.Name)
            .Value(x => x.name);

        this.table.Column()
            .Label(this.i18n.t.core.permissionGroup.permissionType)
            .Value(x => x.permissionList.map(y => this.i18n.t.core.enum.PermissionType[y.type]).join(', '))
            .Priority(3);
    }
}