export enum HttpStatus {
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    METHOD_NOT_ALLOWED = 405,
    CONFLICT = 409,
    PRECONDITION_FAILED = 412,
    EXPECTATION_FAILED = 417,
    UNPROCESSABLE_ENTITY = 422,
    FAILED_DEPENDENCY = 424,
    ERROR = 500
}