import { AppConfig } from 'src/environments/app-config';

/* CORE */
const apiRoute = AppConfig.apiUrl + '/api/';
const userRoute = apiRoute + 'core/User';
const currencyRoute = apiRoute + 'core/Currency';
const measurementUnitRoute = apiRoute + 'core/MeasurementUnit';
const permissionGroupRoute = apiRoute + 'core/PermissionGroup';
const logTextRoute = apiRoute + 'core/LogText';
const auditModelRoute = apiRoute + 'core/Audit';

/* CRM */
const companyRoute = apiRoute + 'crm/Company';


/* PRODUCTS */
const productTypeConferenceTypeRoute = apiRoute + 'products/ProductTypeConferenceType';
const productTypeRoute = apiRoute + 'products/ProductType';
const productRoute = apiRoute + 'products/Product';
const supplierCompanyRoute = apiRoute + 'products/SupplierCompany';
const supplierProductRoute = apiRoute + 'products/SupplierProduct';


/* STOCK */
const stockMovementRoute = apiRoute + 'stock/StockMovement';
const stockProductTypeRoute = apiRoute + 'stock/StockProductType';
const stockProductRoute = apiRoute + 'stock/StockProduct';
const transporterCompanyRoute = apiRoute + 'stock/TransporterCompany';


/* QUALITY */
const qualityReviewRoute = apiRoute + 'quality/QualityReview';


/* PRODUCTION */
const reactorRoute = apiRoute + 'production/Reactor';
const productionRecipeRoute = apiRoute + 'production/ProductionRecipe';
const productionBatchRoute = apiRoute + 'production/ProductionBatch';
const productApplicationRoute = apiRoute + 'production/ProductApplication';


export const ApiRoute = {
    /* CORE */
    auth: userRoute + '/authorize',
    msAuth: userRoute + '/authorize/ms',
    user: {
        default: userRoute,
        filter: userRoute + '/filter',
        profile: userRoute + '/profile',
        password: userRoute + '/profile/password',
    },
    currency: {
        default: currencyRoute,
        filter: currencyRoute + '/filter',
        list: currencyRoute + '/list',
    },
    measurementUnit: {
        default: measurementUnitRoute,
        filter: measurementUnitRoute + '/filter',
        list: measurementUnitRoute + '/list',
    },
    permissionGroup: {
        default: permissionGroupRoute,
        filter: permissionGroupRoute + '/filter',
        list: permissionGroupRoute + '/list',
    },
    logText: {
        default: logTextRoute,
        filter: logTextRoute + '/filter'
    },
    auditModel: {
        default: auditModelRoute,
        filter: auditModelRoute + '/filter',
        filterEntities: auditModelRoute + '/filter/entities',
    },

    /* CRM */
    company: {
        default: companyRoute,
        filter: companyRoute + '/filter',
        list: companyRoute + '/list',
    },

    /* PRODUCTS */
    productType: {
        default: productTypeRoute,
        filter: productTypeRoute + '/filter',
        list: productTypeRoute + '/list',
    },
    productTypeConferenceType: {
        default: productTypeConferenceTypeRoute,
        filter: productTypeConferenceTypeRoute + '/filter',
        list: productTypeConferenceTypeRoute + '/list',
    },
    product: {
        default: productRoute,
        filter: productRoute + '/filter',
        autocomplete: productRoute + '/autocomplete',
        listManudacturers: productRoute + '/manufacturer/list',
    },
    supplierCompany: {
        default: supplierCompanyRoute,
        filter: supplierCompanyRoute + '/filter',
        list: supplierCompanyRoute + '/list',
    },
    supplierProduct: {
        default: supplierProductRoute,
        filter: supplierProductRoute + '/filter',
    },


    /* STOCK */
    stockMovement: {
        default: stockMovementRoute,
        filter: stockMovementRoute + '/filter',
        autocompleteProducts: stockMovementRoute + '/filter/products',
        xmlToBatch: stockMovementRoute + '/xml-to-batch',
        listSuppliers: stockMovementRoute + '/supplier/list',
        batch: stockMovementRoute + '/batch',
    },
    stockProductType: {
        default: stockProductTypeRoute,
        filter: stockProductTypeRoute + '/filter',
    },
    stockProduct: {
        productType: stockProductRoute + '/product-type/{productTypeId}',
        batchNumbers: stockProductRoute + '/product-batch-number',
    },
    transporterCompany: {
        default: transporterCompanyRoute,
        filter: transporterCompanyRoute + '/filter',
        list: transporterCompanyRoute + '/list',
    },

    /* QUALITY */
    qualityReview: {
        default: qualityReviewRoute,
        filter: qualityReviewRoute + '/filter',

        internalProducts: {
            default: qualityReviewRoute + '/internal-products',
            filter: qualityReviewRoute + '/internal-products/filter'
        },

        otherCategories: {
            default: qualityReviewRoute + '/other-categories',
            filter: qualityReviewRoute + '/other-categories/filter'
        }
    },

    /* PRODUCTION */
    reactor: {
        default: reactorRoute,
        filter: reactorRoute + '/filter',
        list: reactorRoute + '/list',
    },
    productionRecipe: {
        default: productionRecipeRoute,
        filter: productionRecipeRoute + '/filter',
        autocompleteProducts: productionRecipeRoute + '/filter/products',
        autocompleteProductTypes: productionRecipeRoute + '/filter/product-types',
        list: productionRecipeRoute + '/list',
        checkStock: productionRecipeRoute + '/check-stock',
        checkExecution: productionRecipeRoute + '/check-execution',
    },
    productionBatch: {
        default: productionBatchRoute,
        filter: productionBatchRoute + '/filter',
        filterExecution: productionBatchRoute + '/filter/execution',
    },
    productApplication: {
        default: productApplicationRoute,
        filter: productApplicationRoute + '/filter',
    }

}
