import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteConfig } from './modules/core/enums/route-config';
import { CurrencyPage } from './modules/core/pages/currency/currency.page';
import { HomePage } from './modules/core/pages/home/home.page';
import { LogTextPage } from './modules/core/pages/log-text/log-text.page';
import { LoginPage } from './modules/core/pages/login/login.page';
import { MeasurementUnitPage } from './modules/core/pages/measurement-unit/measurement-unit.page';
import { PermissionGroupPage } from './modules/core/pages/permission-group/permission-group.page';
import { PrivateBasePage } from './modules/core/pages/private-base/private-base.page';
import { UserProfilePage } from './modules/core/pages/user-profile/user-profile.page';
import { UserPage } from './modules/core/pages/user/user.page';
import { AuthService } from './modules/core/services/auth.service';
import { CrmRouteConfig } from './modules/crm/crm-route-config';
import { ProductionRouteConfig } from './modules/production/production-route-config';
import { ProductsRouteConfig } from './modules/products/products-route-config';
import { QualityRouteConfig } from './modules/quality/quality-route-config';
import { StockRouteConfig } from './modules/stock/products-route-config';
import { AuditModelPage } from './modules/core/pages/audit-model/audit-model.page';

const routes: Routes = [
  { path: RouteConfig.login, component: LoginPage, canActivate: [AuthService] },

  {
    path: '', component: PrivateBasePage, canActivate: [AuthService], children: [
      { path: RouteConfig.home, component: HomePage },
      { path: RouteConfig.user, component: UserPage },
      { path: RouteConfig.userProfile, component: UserProfilePage },
      { path: RouteConfig.currency, component: CurrencyPage },
      { path: RouteConfig.measurementUnit, component: MeasurementUnitPage },
      { path: RouteConfig.permissionGroup, component: PermissionGroupPage },
      { path: RouteConfig.logText, component: LogTextPage },
      { path: RouteConfig.auditModel, component: AuditModelPage },
      

      { path: CrmRouteConfig.module, loadChildren: () => import(`./modules/crm/crm.module`).then(m => m.CrmModule) },
      { path: ProductsRouteConfig.module, loadChildren: () => import(`./modules/products/products.module`).then(m => m.ProductsModule) },
      { path: StockRouteConfig.module, loadChildren: () => import(`./modules/stock/stock.module`).then(m => m.StockModule) },
      { path: QualityRouteConfig.module, loadChildren: () => import(`./modules/quality/quality.module`).then(m => m.QualityModule) },
      { path: ProductionRouteConfig.module, loadChildren: () => import(`./modules/production/production.module`).then(m => m.ProductionModule) },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
