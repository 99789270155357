import { Currency } from "../currency";
import { BaseForm } from "./base/base-form";
import { FormInput } from "./base/form-input";

export class CurrencyForm extends BaseForm<Currency> {
    name: FormInput<string>;
    namePlural: FormInput<string>;
    symbol: FormInput<string>;

    configure() {
        this.name = this.Input<string>(this.i18n.t.core.currency.name).Required();
        this.namePlural = this.Input<string>(this.i18n.t.core.currency.namePlural);
        this.symbol = this.Input<string>(this.i18n.t.core.currency.symbol).Required();

        if (this.model) {
            this.name.SetValue(this.model.name);
            this.namePlural.SetValue(this.model.namePlural);
            this.symbol.SetValue(this.model.symbol);
        }
    }

    getDTO(): Currency {
        let dto = new Currency();
        dto.name = this.name.value;
        dto.namePlural = this.namePlural.value;
        dto.symbol = this.symbol.value;
        if (this.model) dto.id = this.model.id;
        return dto;
    }

}