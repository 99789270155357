import { Component } from "@angular/core";
import { ApiRoute } from "../../enums/api-route";
import { LogTextForm } from "../../models/forms/log-text.form";
import { LogText } from "../../models/log-text";
import { BaseFilterPage } from "../base-filter-page";
import { BasePageDeps } from "../base-page-deps";

@Component({
    selector: 'app-log-text-page',
    templateUrl: './log-text.page.html',
    styleUrls: ['./log-text.page.less']
})
export class LogTextPage extends BaseFilterPage<LogText, LogTextForm> {
    constructor(deps: BasePageDeps) {
        super(deps, LogText, LogTextForm, ApiRoute.logText.filter, ApiRoute.logText.default);
        this.title = this.i18n.t.navigation.core.logText;
    }

    // createFilter() {
    //     this.filter.CreateField(this.i18n.t.core.user.name, FilterFieldUser.NAME);
    //     this.filter.CreateField(this.i18n.t.core.user.email, FilterFieldUser.EMAIL);
    //     this.filter.CreateField(this.i18n.t.core.user.username, FilterFieldUser.USERNAME);
    // }

    createTable() {
        this.table.Action(this.icon.edit, model => this.edit(model)).Tooltip(this.i18n.t.core.label.edit);
        // this.table.Action(this.icon.delete, model => this.delete(model)).Tooltip(this.i18n.t.core.label.delete);

        this.table.Column()
            .Label('Data')
            // .OrderBy(FilterFieldUser.NAME)
            .Value(x => x.createdDate.toLocaleString())
            .Priority(3);

        this.table.Column()
            .Label('Type')
            // .OrderBy(FilterFieldUser.EMAIL)
            .Value(x => x.type)
            .Priority(4);

        this.table.Column()
            .Label('Group')
            // .OrderBy(FilterFieldUser.EMAIL)
            .Value(x => x.group)
            .Priority(4);

        this.table.Column()
            .Label('Content')
            // .OrderBy(FilterFieldUser.EMAIL)
            .Value(x => x.content)
            .Priority(4);
    }
}