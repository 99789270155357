import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../models/user';

import { HttpStatus } from '../enums/http-status';
import { RouteConfig } from '../enums/route-config';
import { StorageTable } from '../enums/storage-table';
import { I18n } from '../i18n';
import { AlertHandler } from './alert.handler';
import { StorageHandler } from './storage.handler';


@Injectable()
export class ServiceHandler {

    constructor(
        public http: HttpClient,
        public router: Router
    ) { }

    Post(path: string, body: any, isFullPath = false): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.post(this.apiPath(path, isFullPath), body, this.GetHeaders()).toPromise()
                .then(d => { resolve(d); })
                .catch(err => this.handleError(err, reject));
        });
    }

    Put(path: string, body: any, isFullPath = false): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.put(this.apiPath(path, isFullPath), body, this.GetHeaders()).toPromise()
                .then(d => { resolve(d); })
                .catch(err => this.handleError(err, reject));
        });
    }

    Get(path: string, isFullPath = false): Promise<any> {
        return new Promise((resolve, reject) => {
            let headers = this.GetHeaders();
            this.http.get(this.apiPath(path, isFullPath), headers).toPromise()
                .then(d => {
                    resolve(d);
                })
                .catch(err => this.handleError(err, reject));
        });
    }

    Delete(path: string, isFullPath = false): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.delete(this.apiPath(path, isFullPath), this.GetHeaders()).toPromise()
                .then(d => { resolve(d); })
                .catch(err => this.handleError(err, reject));
        });
    }

    private GetHeaders(): any {
        let token = this.GetAuthToken();
        if (token) {
            let h = new HttpHeaders();
            h = h.set("Authorization", 'Bearer ' + token);
            return { headers: h };
        }
    }

    private GetAuthToken() {
        let userList: Array<User> = StorageHandler.List(StorageTable.USER)
        if (userList.length) return userList[0].token;
    }

    private handleError(err, rejectFunction) {
        if (err.status == HttpStatus.UNAUTHORIZED &&
            !window.location.pathname.Contains(RouteConfig.login) &&
            window.location.pathname != "/") {
            StorageHandler.DeleteTable(StorageTable.USER);
            this.router.navigate([RouteConfig.login]);
        }
        if (err.status == HttpStatus.FORBIDDEN &&
            !window.location.pathname.Contains(RouteConfig.login) &&
            window.location.pathname != "/") {
            this.router.navigate([RouteConfig.home]);
        }
        // this.logger.error(err);
        if (typeof (rejectFunction) == 'function') rejectFunction(err);
    }

    handleRequestError(error, errorMessageMap, i18n: I18n, alert: AlertHandler) {
        let message = !errorMessageMap[error.status]
            ? i18n.t.core.message.saveError
            : !errorMessageMap[error.status][error.error]
                ? errorMessageMap[error.status]
                : errorMessageMap[error.status][error.error];

        return alert.error(message);
    }

    private apiPath(path: string, isFullPath: boolean) {
        // if(path.Contains("http") || isFullPath) return path;
        // return AppConfig.apiURL + path;
        return path;
    }
}