import { Component } from "@angular/core";
import { ApiRoute } from "../../enums/api-route";
import { ServiceHandler } from "../../handlers/service.handler";
import { AuditModel, AuditModelFilterField } from "../../models/audit-model";
import { FilterField } from "../../models/filter/filter-field";
import { FilterFieldSearchType } from "../../models/filter/filter-field-search-type";
import { FilterOrder } from "../../models/filter/filter-order";
import { AuditModelForm } from "../../models/forms/audit-model.form";
import { FormInput } from "../../models/forms/base/form-input";
import { FormInputOptions } from "../../models/forms/base/form-input-options";
import { FormInputType } from "../../models/forms/base/form-input-type";
import { LocalTable } from "../../models/table/local-table";
import { BaseFilterPage } from "../base-filter-page";
import { BasePageDeps } from "../base-page-deps";

@Component({
    selector: 'app-audit-model-page',
    templateUrl: './audit-model.page.html',
    styleUrls: ['./audit-model.page.less']
})
export class AuditModelPage extends BaseFilterPage<AuditModel, AuditModelForm> {

    entities: string[] = [];

    constructor(deps: BasePageDeps, private serviceHandler: ServiceHandler) {
        super(deps, AuditModel, AuditModelForm, ApiRoute.auditModel.filter, ApiRoute.auditModel.default);
        this.title = this.i18n.t.navigation.core.logText;
        this.hideDelete = true;
        this.hideInclude = true;
        this.hideSave = true;

        this.headerCustomButtons.push({
            icon: this.icon.table,
            action: () => this.showTable(this.table.GetSelectedData()),
            label: 'Ver tabela',
            show: () => this.table.selectedData.Any()
        });
    }

    filterEntity: FilterField;
    filterModelId: FilterField;
    filterRequestId: FilterField;
    createFilter() {
        this.filterEntity = this.filter.CreateField('Entity', AuditModelFilterField.Entity)
            .FilterType(FilterFieldSearchType.EQUAL)
            .Options(this.entities.map(x => new FormInputOptions(x, x)));

        this.filterModelId = this.filter.CreateField('Id', AuditModelFilterField.ModelId, FormInputType.NUMBER)
            .FilterType(FilterFieldSearchType.EQUAL)

        this.filterRequestId = this.filter.CreateField('RequestId', AuditModelFilterField.AuditRequestId)
            .FilterType(FilterFieldSearchType.EQUAL)

        this.filter.orderDirection = FilterOrder.Desc;
    }

    loadScreenDeps(): Promise<void> {
        return new Promise(resolve => {
            Promise.all([
                this.getEntities()
            ])
                .then(values => {
                    this.entities = values[0].OrderBy(x => x) as string[];
                })
                .catch(() => { })
                .then(() => { resolve() })
        });
    }

    createTable() {
        this.table.Action(this.icon.table, model => this.showTable([model]))
        // this.table.Action(this.icon.delete, model => this.delete(model)).Tooltip(this.i18n.t.core.label.delete);

        this.table.SelectionInput();

        this.table.Column()
            .Label('Modificação')
            .OrderBy(AuditModelFilterField.CreatedDate)
            .Value(x => x.createdDate.toLocaleString(this.i18n.t.calendar.locale) + '.' + x.createdDate.getMilliseconds().toString().padStart(3, '0'))
            .Priority(3);

        this.table.Column()
            .Label('Tabela')
            .OrderBy(AuditModelFilterField.Entity)
            .Value(x => x.entity)
            .Priority(3)
            .OnClick(x => this.addOnFilter(this.filterEntity, x.entity))

        this.table.Column()
            .Label('Id')
            .OrderBy(AuditModelFilterField.ModelId)
            .Value(x => x.modelId)
            .Priority(3)
            .OnClick(x => this.addOnFilter(this.filterModelId, x.modelId))

        this.table.Column()
            .Label('User')
            // .OrderBy(FilterFieldUser.NAME)
            .Value(x => x.userName ?? '-')
            .Priority(3);

        this.table.Column()
            .Label('Action')
            .OrderBy(AuditModelFilterField.Action)
            .Value(x => x.action)
            .Priority(3);

        this.table.Column()
            .Label('Request')
            .OrderBy(AuditModelFilterField.AuditRequestId)
            .Value(x => x.auditRequestId)
            .Priority(3)
            .OnClick(x => this.addOnFilter(this.filterRequestId, x.auditRequestId))
    }

    addOnFilter(targetFilter: FilterField, value: any) {
        if (targetFilter.value != value) {
            targetFilter.SetValue(value);
            this.filter.resetPage()
        }
    }

    getEntities(): Promise<string[]> {
        return this.service.Get(ApiRoute.auditModel.filterEntities);
    }

    compareTable: LocalTable<AuditModel>
    showColumns: FormInput<string[]>;

    showTable(models: AuditModel[]): void {
        let firstModel = models.First();

        if (models.Any(x => x.entity != firstModel.entity)) {
            this.alert.error('Não pode haver Entidades diferentes na seleção');
            return;
        }
        this.compareTable = new LocalTable();
        this.compareTable.Title(firstModel.entity)

        var ignoreColumns = ['CreatedDate', 'CreatedUserId', 'UpdatedDate', 'UpdatedUserId'];
        let columns: string[] = [];
        for (let key in firstModel.modelObject) {
            columns.push(key);
        }

        this.showColumns = new FormInput<string[]>('Colunas', columns.filter(x => !ignoreColumns.Contains(x)))
            .Options(columns.map(x => new FormInputOptions(x, x)))
            .Type(FormInputType.MULTI_SELECT)
            .OnChange(() => this.configureTableContent(firstModel));

        this.configureTableContent(firstModel);
        this.compareTable.Data(models);
        this.showDetail = true;
    }

    configureTableContent(firstModel: AuditModel) {
        this.compareTable.columnList = [];

        this.compareTable.Column()
            .Label('Modificação')
            .Value(x => x.createdDate.toLocaleString(this.i18n.t.calendar.locale) + '.' + x.createdDate.getMilliseconds().toString().padStart(3, '0'));

        this.compareTable.Column()
            .Label('Usuário')
            .Value(x => x.userName ?? '-');


        for (let key in firstModel.modelObject) {
            if (this.showColumns.value.Contains(key)) {
                this.compareTable.Column()
                    .Label(key)
                    .Value(x => x.modelObject[key]);
            }
        }
    }
}