import { AfterViewInit, Component, Input, ViewChild } from "@angular/core";
import { Paginator } from "primeng/paginator";
import { I18nTags } from '../../enums/i18n-tags';
import { I18n } from '../../i18n';
import { Filter } from '../../models/filter/filter';
import { FormInput } from "../../models/forms/base/form-input";
import { FormInputType } from "../../models/forms/base/form-input-type";
import { Table } from '../../models/table/table';
import { TableColumn } from '../../models/table/table-column';

@Component({
    selector: 'g-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.less']
})
export class TableComponent<T> implements AfterViewInit {

    @Input() table: Table<T>;
    @Input() filter: Filter<T>;
    @ViewChild('paginator') paginator: Paginator;
    masterSelection = new FormInput<boolean>('', false).Type(FormInputType.CHECKBOX);

    constructor(public i18n: I18n) {
        this.configureMasterSelection();
    }

    sort(event) {
        this.filter.sort(event);
    }

    getActionsWidth() {
        return (this.table.actionList.length * 50) + 'px';
    }

    getPriorityClass(column: TableColumn<T>) {
        let cl = '';
        if (column.priority && column.priority > 5) {
            cl += ' ui-p-6';
        } else if (column.priority && column.priority > 0) {
            cl += ' ui-p-' + column.priority;
        }
        return cl;
    }

    getClassName(column: TableColumn<T>, item: T) {
        return column.getClassName(item);
    }


    ngAfterViewInit() {
        this.table.tableComponent = this;
        if (this.filter) {
            this.filter.AfterSearch(() => {
                this.table.data = this.filter.data;
                this.configureSelectionInput();
            });
            this.filter.OnResetPage(() => {
                this.paginator.changePage(this.filter.page - 1);
            });
            setTimeout(() => {
                this.paginator.changePage(this.filter.page - 1);
            }, 10);
        }
    }

    configureSelectionInput() {
        this.masterSelection.value = false;
        let hasNotSelected = false;
        this.table.data.forEach((tableItem: any) => {
            let item = tableItem as TableComponentItem;
            if (!item.tableComponent_selectionInput) {
                item.tableComponent_selectionInput = new FormInput<boolean>('', false).Type(FormInputType.CHECKBOX);
                if (this.table.selectedData.Any(x => item.id == (x as any).id)) {
                    item.tableComponent_selectionInput.value = true;
                }
                item.tableComponent_selectionInput.OnChange(() => {
                    if (item.tableComponent_selectionInput.value) {
                        this.table.selectedData.push(item as any);
                    } else {
                        this.table.selectedData = this.table.selectedData.filter(x => (x as any).id != item.id)
                    }

                    if (this.table.data.Any(x => !(x as any).tableComponent_selectionInput.value)) {
                        this.masterSelection.value = false;
                    } else {
                        this.masterSelection.value = true;
                    }
                });
            }

            if (!item.tableComponent_selectionInput.value) {
                hasNotSelected = true;
            }
        })
        if (!hasNotSelected) {
            this.masterSelection.value = true;
        }
    }

    configureMasterSelection() {
        this.masterSelection.OnChange(() => {
            if (this.masterSelection.value) {
                this.table.data.forEach((tableItem: any) => {
                    let item = tableItem as TableComponentItem;
                    if (!item.tableComponent_selectionInput.value) {
                        item.tableComponent_selectionInput.SetValue(true);
                    }
                })
            } else {
                this.table.data.forEach((tableItem: any) => {
                    let item = tableItem as TableComponentItem;
                    if (item.tableComponent_selectionInput.value) {
                        item.tableComponent_selectionInput.SetValue(false);
                    }
                })
            }
        });
    }

    getCounterString() {
        let startCount = ((this.filter.page - 1) * this.filter.pageSize) + 1;
        let endCount = this.filter.page * this.filter.pageSize;
        endCount = endCount < this.filter.totalResults ? endCount : this.filter.totalResults;
        return this.i18n.t.core.label.filterResult
            .replace(I18nTags.Total, this.filter.totalResults.toString())
            .replace(I18nTags.StarCount, startCount.toString())
            .replace(I18nTags.EndCount, endCount.toString());
    }

    onChangePageSize() {
        if (this.filter) this.filter.onChangePageSize();
    }

    hasClickFunction(column: TableColumn<T>, item: T) {
        return column.onClick != null;
    }

    executeClick(column: TableColumn<T>, item: T) {
        if(this.hasClickFunction(column, item)){
            column.onClick(item);
        }
    }
}

interface TableComponentItem {
    id: number;
    tableComponent_selectionInput: FormInput<boolean>
}