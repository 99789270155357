import { BaseEntity } from "./base-entity";
import { PermissionGroupUser } from "./permission-group-user";

export class User extends BaseEntity {
    name: string;
    username: string;
    email: string;
    token: string;
    permissionGroupUsers: PermissionGroupUser[] = [];

    password: string; // Only when create

    static fromData(data: any) {
        const e: User = super.fromData(data);
        if (e.permissionGroupUsers) {
            e.permissionGroupUsers = e.permissionGroupUsers.map(x => PermissionGroupUser.fromData(x));
        }
        return e;
    }
}


export enum UserFilterField {
    Username = 'Username',
    Name = 'Name',
    Email = 'Email',
    PermissionGroupId = 'PermissionGroupId',
}

