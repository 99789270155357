<g-filter [filter]="filter"></g-filter>

<div class="page-content">
    <g-table [filter]="filter" [table]="table"></g-table>
</div>


<g-form-dialog [page]="this" [title]="title">
    <div class="form ui-g">
        <div class="ui-g-12">
            <g-form-input [input]="form.name"></g-form-input>
        </div>
        <div class="ui-g-12">
            <g-form-input [input]="form.namePlural"></g-form-input>
        </div>
        <div class="ui-g-12">
            <g-form-input [input]="form.symbol"></g-form-input>
        </div>  

        <div class="ui-g-12">
            <g-form-input [input]="form.dimension"></g-form-input>
        </div>  

        <div class="ui-g-12">
            <g-form-input [input]="form.defaultConversionFactor"></g-form-input>
        </div>  

        <div class="ui-g-12">
            <g-form-input [input]="form.isDefault"></g-form-input>
        </div>  
    </div>
</g-form-dialog>
