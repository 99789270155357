import { BasePage } from '../../../pages/base-page';
import { LocalTable } from '../../table/local-table';
import { BaseForm } from './base-form';

export class TableForm<T extends BaseForm<any>> extends LocalTable<T> {

    formType: any;
    canAddLines: boolean = true;
    showValidation = false;
    getTextTopRow: (form: T) => string | null = f => null;
    resolveDependencies: (form: T) => void = f => { };
    afterCreateInstance: (form: T) => void = f => { };
    viewMode = false;
    originPage: BasePage;

    Form(formType: any) {
        this.formType = formType;
        return this;
    }

    ModelData(modelList: any[], createIfNull = false) {
        if (modelList) {
            let formList = modelList.map(model => this.createForm(model));
            this.Data(formList);
        } else if (createIfNull) {
            this.CreateLine();
        }
    }

    AddModel(model) {
        let newForm = this.createForm(model);
        this.Add(newForm);
    }

    private createForm(model): T {
        let form = new this.formType() as BaseForm<any>;
        form.originPage = this.originPage;
        this.resolveDependencies(form as T);
        form.Model(model);
        form.TextTopRow(this.getTextTopRow);
        this.afterCreateInstance(form as T);
        return form as T;
    }

    CreateLine() {
        this.Add(this.createForm(null));
    }

    ResolveDeps(resolveDependencies: (form: T) => void) {
        this.resolveDependencies = resolveDependencies;
        return this;
    }

    AfterCreateInstance(afterCreateInstance: (form: T) => void) {
        this.afterCreateInstance = afterCreateInstance;
        return this;
    }

    TextTopRow(getTextTopRow: (form: T) => string) {
        this.getTextTopRow = getTextTopRow;
        this.ForEach(f => f.TextTopRow(getTextTopRow));
        return this;
    }

    getDTOList(): any {
        return this.dataRaw.map(x => x.getDTO());
    }

    getErrorMessage() {
        for (let i = 0; i < this.data.length; i++) {
            let m = this.data[i].getErrorMessage();
            if (m) return m;
        }
    }

    PushUp(item: T) {
        let beforeList = [];
        let afterList = [];
        let itemFound = false;

        for (let i = 0; i < this.dataRaw.length; i++) {
            if (this.dataRaw[i].guid == item.guid) {
                itemFound = true;
            } else if (itemFound) {
                afterList.push(this.dataRaw[i]);
            } else {
                beforeList.push(this.dataRaw[i]);
            }
        }
        if (!beforeList.Any()) return;

        let lastBeforeItem = beforeList.Last();
        beforeList = beforeList.filter(x => x.guid != lastBeforeItem.guid);
        beforeList.push(item);
        beforeList.push(lastBeforeItem);
        afterList.forEach(x => beforeList.push(x));
        this.Data(beforeList);
    }

    PushDown(item: T) {
        let beforeList = [];
        let afterList = [];
        let itemFound = false;

        for (let i = 0; i < this.dataRaw.length; i++) {
            if (this.dataRaw[i].guid == item.guid) {
                itemFound = true;
            } else if (itemFound) {
                afterList.push(this.dataRaw[i]);
            } else {
                beforeList.push(this.dataRaw[i]);
            }
        }
        if (!afterList.Any()) return;

        let firstAfterItem = afterList.First();
        beforeList.push(firstAfterItem);
        beforeList.push(item);
        afterList = afterList.filter(x => x.guid != firstAfterItem.guid);
        afterList.forEach(x => beforeList.push(x));
        this.Data(beforeList);
    }

    CanAddLines(canAddLines: boolean) {
        this.canAddLines = canAddLines;
    }

    ShowValidation(showValidation: boolean = true) {
        this.showValidation = showValidation;
        this.ForEach(x => x.ShowValidation(this.showValidation));
        return this;
    }

    ViewMode() {
        this.viewMode = true;
        this.ForEach(x => x.ViewMode())
        this.CanAddLines(false);
    }

    PermissionEdit() {
        return !this.originPage || this.originPage.permissionEdit(); 
    }

    PermissionInclude() {
        return !this.originPage || this.originPage.permissionInclude(); 
    }

    PermissionDelete() {
        return !this.originPage || this.originPage.permissionDelete(); 
    }
}

