import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalModule } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { BlockUIModule } from 'ng-block-ui';
import { ToastrModule } from 'ngx-toastr';
import { AppConfig } from 'src/environments/app-config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';
import { AlertHandler } from './modules/core/handlers/alert.handler';
import { BlockHandler } from './modules/core/handlers/block.handler';
import { LogHandler } from './modules/core/handlers/log.handler';
import { ServiceHandler } from './modules/core/handlers/service.handler';
import { I18n } from './modules/core/i18n';
import { BasePageDeps } from './modules/core/pages/base-page-deps';
import { CurrencyPage } from './modules/core/pages/currency/currency.page';
import { HomePage } from './modules/core/pages/home/home.page';
import { LogTextPage } from './modules/core/pages/log-text/log-text.page';
import { LoginPage } from './modules/core/pages/login/login.page';
import { MeasurementUnitPage } from './modules/core/pages/measurement-unit/measurement-unit.page';
import { PermissionGroupPage } from './modules/core/pages/permission-group/permission-group.page';
import { PrivateBasePage } from './modules/core/pages/private-base/private-base.page';
import { UserPage } from './modules/core/pages/user/user.page';
import { AuthService } from './modules/core/services/auth.service';
import { CurrencyService } from './modules/core/services/currency.service';
import { MeasurementUnitService } from './modules/core/services/measurement-unit.service';
import { PageRouteService } from './modules/core/services/page-route.service';
import { PermissionGroupService } from './modules/core/services/permission-group.service';
import { UserService } from './modules/core/services/user.service';
import { UtilsService } from './modules/core/services/utils.service';
import './utils/array';
import './utils/date';
import './utils/number';
import './utils/string';
import { UserProfilePage } from './modules/core/pages/user-profile/user-profile.page';
import { StockProductService } from './modules/stock/services/stock-product.service';
import { AuditModelPage } from './modules/core/pages/audit-model/audit-model.page';


@NgModule({
  declarations: [
    AppComponent,
    LoginPage,
    HomePage,
    UserPage,
    CurrencyPage,
    MeasurementUnitPage,
    PermissionGroupPage,
    LogTextPage,
    PrivateBasePage,
    UserProfilePage,
    AuditModelPage,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      // timeOut: 3500,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    BlockUIModule.forRoot(),
    CoreModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: AppConfig.msal.clientId,
          authority: "https://login.microsoftonline.com/" + AppConfig.msal.tenantId,
        }
      }),
      null,
      null
    ),

  ],
  providers: [
    ServiceHandler,
    AlertHandler,
    BlockHandler,
    LogHandler,
    I18n,
    BasePageDeps,
    AuthService,
    UserService,
    PageRouteService,
    UtilsService,
    CurrencyService,
    MeasurementUnitService,
    PermissionGroupService,
    StockProductService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
