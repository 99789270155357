import { MeasurementUnitDimension } from "../enums/measurement-unit-dimention";
import { BaseEntity } from "./base-entity";

export class MeasurementUnit extends BaseEntity {
    name: string;
    namePlural: string;
    symbol: string;
    dimension: MeasurementUnitDimension;
    defaultConversionFactor: number;
    isDefault: boolean;
}
