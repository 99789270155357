import { Component } from '@angular/core';
import { ApiRoute } from '../../enums/api-route';
import { FilterFieldMeasurementUnit } from '../../enums/filter-field-measurement-unit';
import { MeasurementUnitDimension } from '../../enums/measurement-unit-dimention';
import { FormInputOptions } from '../../models/forms/base/form-input-options';
import { FormInputType } from '../../models/forms/base/form-input-type';
import { MeasurementUnitForm } from '../../models/forms/measurement-unit.form';
import { MeasurementUnit } from '../../models/measurement-unit';
import { BaseFilterPage } from '../base-filter-page';
import { BasePageDeps } from '../base-page-deps';
import { PermissionType } from '../../enums/permission-type';

@Component({
    selector: 'app-core-measurement-unit-page',
    templateUrl: './measurement-unit.page.html',
    styleUrls: ['./measurement-unit.page.less']
})
export class MeasurementUnitPage extends BaseFilterPage<MeasurementUnit, MeasurementUnitForm> {
    constructor(deps: BasePageDeps) {
        super(deps, MeasurementUnit, MeasurementUnitForm, ApiRoute.measurementUnit.filter, ApiRoute.measurementUnit.default);
        this.title = this.i18n.t.core.measurementUnit.title;
        this.mainPermissionType = PermissionType.Core_MeasurementUnit;
    }

    createFilter() {
        this.filter.CreateField(
            this.i18n.t.core.measurementUnit.name + " | " + this.i18n.t.core.measurementUnit.namePlural,
            FilterFieldMeasurementUnit.NAME);
        this.filter.CreateField(this.i18n.t.core.measurementUnit.symbol, FilterFieldMeasurementUnit.SYMBOL);
        this.filter.CreateField(this.i18n.t.core.measurementUnit.dimension, FilterFieldMeasurementUnit.DIMENSION)
            .Type(FormInputType.SELECT)
            .Options(FormInputOptions.fromEnum(MeasurementUnitDimension, this.i18n.t.core.enum.MeasurementUnitDimension), true)
        this.filter.CreateField(this.i18n.t.core.measurementUnit.isDefault, FilterFieldMeasurementUnit.IS_DEFAULT)
            .Type(FormInputType.SELECT)
            .Options(FormInputOptions.boolean(), true);
    }

    createTable() {
        this.defaultCrudTableActions();

        this.table.Column()
            .Label(this.i18n.t.core.measurementUnit.name)
            .OrderBy(FilterFieldMeasurementUnit.NAME)
            .Value(x => x.name)
            .Priority(3);

        this.table.Column()
            .Label(this.i18n.t.core.measurementUnit.namePlural)
            .OrderBy(FilterFieldMeasurementUnit.NAME_PLURAL)
            .Value(x => x.namePlural)
            .Priority(4);

        this.table.Column()
            .Label(this.i18n.t.core.measurementUnit.symbol)
            .OrderBy(FilterFieldMeasurementUnit.SYMBOL)
            .Value(x => x.symbol);

        this.table.Column()
            .Label(this.i18n.t.core.measurementUnit.dimension)
            .OrderBy(FilterFieldMeasurementUnit.DIMENSION)
            .Value(x => this.i18n.t.core.enum.MeasurementUnitDimension[x.dimension] )
            .Priority(5);

        this.table.Column()
            .Label(this.i18n.t.core.measurementUnit.isDefault)
            .OrderBy(FilterFieldMeasurementUnit.IS_DEFAULT)
            .Value(x => this.i18n.YesNo(x.isDefault))
            .Priority(6);
    }
}
