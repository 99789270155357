export const RouteConfig = {
    home: '',
    login: 'login',
    user: 'user',
    userProfile: 'user-profile',
    currency: 'currency',
    measurementUnit: 'measurement-unit',
    permissionGroup: 'permission-group',
    logText: 'log-text',
    auditModel: 'audit-model',
}