import { PageType } from "../enums/page-type";
import { BaseEntity } from "../models/base-entity";
import { Filter } from "../models/filter/filter";
import { BaseForm } from "../models/forms/base/base-form";
import { Table } from "../models/table/table";
import { TableColumnAction } from "../models/table/table-column-action";
import { BaseDetailPage } from "./base-detail-page";
import { BasePageDeps } from "./base-page-deps";

export class BaseFilterPage<TModel extends BaseEntity, TForm extends BaseForm<TModel>>
    extends BaseDetailPage<TModel, TForm> {

    filter: Filter<TModel>;
    table: Table<TModel>;
    showDetail: boolean;

    /**
     * Custom Buttons in the footer main page modal 
     */
    customButtons: {
        icon: any,
        label: string,
        action: Function,
        show?: () => boolean
    }[] = [];



    constructor(
        deps: BasePageDeps,
        TModel: any,
        TForm: any,
        public filterRoute: string,
        defaultRoute: string
    ) {
        super(deps, TModel, TForm, defaultRoute);
        this.type = PageType.FILTER;
        if (!this.recoveredState) {
            this.filter = new Filter<TModel>();
            this.table = new Table<TModel>();
            this.showDetail = false;
            this.filter.configure(filterRoute, TModel, deps);
        }
    }

    ngOnInit() {
        if (this.recoveredState) return this.recoveredInit();
        this.block.start();
        this.loadScreenDeps()
            .then(() => {
                this.checkPermissions();
                this.filter.fields = [];
                this.createFilter();
                this.table.actionList = [];
                this.table.columnList = [];
                this.createTable();
                this.recoveredInit();
            })
            .catch(err => { })
            .then(() => this.block.stop());

    }

    createFilter() { }
    createTable() { }
    afterFirstSearch() { }

    recoveredInit() {
        this.filter.search().then(() => this.afterFirstSearch());
    }

    edit(model: TModel) {
        this.form = this.formDeps(this.createForm());
        setTimeout(() => {
            this.form.Model(model);
            this.showDetail = true;
        }, 10);
    }

    view(model: TModel) {
        this.form = this.formDeps(this.createForm());
        setTimeout(() => {
            this.form.Model(model);
            this.form.ViewMode();
            this.showDetail = true;
        }, 10);
    }

    afterSave() {
        this.filter.search().then(() => this.closeDetails());
    }

    closeDetails() {
        this.showDetail = false
    }

    defaultCrudTableActions(includeDelete = true, editIcon: any = null): TableColumnAction<TModel> {
        if (!editIcon) editIcon = this.icon.edit;
        if (this.permissionEdit()) this.table.Action(editIcon, model => this.edit(model)).Tooltip(this.i18n.t.core.label.edit);
        else this.table.Action(this.icon.view, model => this.view(model)).Tooltip(this.i18n.t.core.label.view);
        if (includeDelete && !this.hideDelete && this.permissionDelete()) {
            return this.table.Action(this.icon.delete, model => this.delete(model)).Tooltip(this.i18n.t.core.label.delete);
        }
        return null;
    }
}